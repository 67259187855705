const LocationReviewsData = [
    {
        "date": "2024-04-22",
        "review_rate": 4.9,
        "customer_name": "Nicole Garza",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Billy P.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-13",
        "review_rate": 5.0,
        "customer_name": "Dr. Aaron Phillips",
        "service_type": "Garage Door Off Track",
        "tech_name": "Bruce V.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-17",
        "review_rate": 5.0,
        "customer_name": "Lisa Daniels",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Alexander C.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.9,
        "customer_name": "Shawn Gross",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jeffrey N.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.9,
        "customer_name": "Richard Smith",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Mackenzie C.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.9,
        "customer_name": "Henry Robinson",
        "service_type": "Garage Door Installation",
        "tech_name": "Gary F.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.8,
        "customer_name": "Jesse Mitchell",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Valerie J.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.8,
        "customer_name": "Barbara Miller",
        "service_type": "Garage Door Insulation",
        "tech_name": "Brandon H.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.8,
        "customer_name": "Erin Santana",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Rachel R.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.9,
        "customer_name": "Denise Paul",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Mary C.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.8,
        "customer_name": "April Lee",
        "service_type": "Garage Door Services",
        "tech_name": "Timothy M.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-28",
        "review_rate": 5.0,
        "customer_name": "Christina Harvey",
        "service_type": "Garage Door Off Track",
        "tech_name": "Randy D.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.9,
        "customer_name": "Jacob Webb",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jay S.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "William Serrano",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Samantha C.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-28",
        "review_rate": 5.0,
        "customer_name": "Melanie Christensen",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Joseph G.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Andrea Foster",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Victoria H.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.8,
        "customer_name": "Eric Roberts",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Regina H.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.9,
        "customer_name": "Gary Hardin",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Sandra S.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Ronald Hall",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Michael H.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Sarah Garrison",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Andrew P.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.9,
        "customer_name": "Mark Bowers",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Brian S.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.8,
        "customer_name": "Ashley Shelton",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Wendy G.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.9,
        "customer_name": "Vincent Curtis",
        "service_type": "Garage Door Services",
        "tech_name": "Brian B.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.9,
        "customer_name": "Mrs. Ashley Rollins",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Julie T.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "Shannon Rios",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Frank P.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.9,
        "customer_name": "Dominic Valdez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Daniel R.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.9,
        "customer_name": "Denise Rivera",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Peter J.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.9,
        "customer_name": "Dawn Chapman",
        "service_type": "Garage Door Off Track",
        "tech_name": "Paul T.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-09",
        "review_rate": 5.0,
        "customer_name": "Janice Gay",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Ricky S.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.8,
        "customer_name": "Matthew Brown",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Erica M.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-18",
        "review_rate": 5.0,
        "customer_name": "Antonio Hodge",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Alexis F.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.9,
        "customer_name": "Annette Lee",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Travis M.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.9,
        "customer_name": "Evelyn Drake",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Scott N.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-25",
        "review_rate": 5.0,
        "customer_name": "Benjamin Moreno",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "John G.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.9,
        "customer_name": "Kelly Watts",
        "service_type": "Garage Door Repair",
        "tech_name": "Stephanie B.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.9,
        "customer_name": "Mrs. Wendy Macias MD",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Henry B.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-05",
        "review_rate": 5.0,
        "customer_name": "Betty Thomas",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Phillip P.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-24",
        "review_rate": 5.0,
        "customer_name": "Kimberly Graves",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Daniel G.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-16",
        "review_rate": 5.0,
        "customer_name": "Michelle Montgomery",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Kelly M.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.9,
        "customer_name": "Peggy Bond",
        "service_type": "Garage Door Opener",
        "tech_name": "James M.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "Brittney Lewis",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Aaron W.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-11",
        "review_rate": 5.0,
        "customer_name": "Susan Wells",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Michael J.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.9,
        "customer_name": "Robert Murphy",
        "service_type": "Garage Door Services",
        "tech_name": "Eric W.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-22",
        "review_rate": 4.9,
        "customer_name": "Alexandra Stephenson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Megan W.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.9,
        "customer_name": "Brenda Orozco",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Chad F.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "Dr. Robert Collins",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Sarah C.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-01",
        "review_rate": 4.8,
        "customer_name": "Amber Hart",
        "service_type": "Garage Door Off Track",
        "tech_name": "Robin C.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.9,
        "customer_name": "Hannah Middleton",
        "service_type": "Garage Door Off Track",
        "tech_name": "Gerald B.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.9,
        "customer_name": "Thomas Juarez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Steven W.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.8,
        "customer_name": "Alexander Ross DVM",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jennifer C.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.9,
        "customer_name": "Darlene Krueger",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Victoria G.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-20",
        "review_rate": 5.0,
        "customer_name": "Vanessa Wright",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jordan K.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.8,
        "customer_name": "Jennifer Gonzalez",
        "service_type": "Garage Door Opener",
        "tech_name": "Michael C.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.9,
        "customer_name": "Nicole Harris",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Cindy M.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-11",
        "review_rate": 5.0,
        "customer_name": "Jessica Miller",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Raymond W.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-02",
        "review_rate": 5.0,
        "customer_name": "Margaret Dunn DDS",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Alexander S.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "Dennis Barr",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jessica W.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.8,
        "customer_name": "Erin Rodriguez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Joshua A.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.8,
        "customer_name": "Dominique Ray",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Samantha M.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.9,
        "customer_name": "Brandon Hutchinson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Christina M.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Chad Wilson",
        "service_type": "Garage Door Services",
        "tech_name": "Heather L.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-23",
        "review_rate": 5.0,
        "customer_name": "Michele Brooks",
        "service_type": "Garage Door Opener",
        "tech_name": "Tamara G.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.9,
        "customer_name": "Regina Peters",
        "service_type": "Garage Door Services",
        "tech_name": "George J.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.8,
        "customer_name": "Danielle Little",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "George H.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.8,
        "customer_name": "Jonathon Moore",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Tammy G.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "Amy Patel",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Christopher K.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-29",
        "review_rate": 5.0,
        "customer_name": "Lynn Cannon",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Joseph G.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Cheryl Roberts",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Christopher S.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.8,
        "customer_name": "Richard Waters",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Brian T.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-17",
        "review_rate": 5.0,
        "customer_name": "Alyssa Fields",
        "service_type": "Garage Door Services",
        "tech_name": "Shannon S.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.9,
        "customer_name": "Isabel Park",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Joshua E.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Michelle Acosta",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Joseph B.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Brian Olson",
        "service_type": "Garage Door Installation",
        "tech_name": "James C.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.9,
        "customer_name": "Dr. Shane Parsons",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Melanie S.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.8,
        "customer_name": "Christine Dominguez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Madison T.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Brian Porter",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Mark W.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.9,
        "customer_name": "Eric Mcfarland",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jason B.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-30",
        "review_rate": 5.0,
        "customer_name": "Daniel Davis",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Eric W.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.9,
        "customer_name": "Ryan Torres",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Roy H.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-04",
        "review_rate": 5.0,
        "customer_name": "Julie Hart",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Lauren A.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-15",
        "review_rate": 5.0,
        "customer_name": "Donna Hardy",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jennifer W.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-13",
        "review_rate": 5.0,
        "customer_name": "Lauren Kramer",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Brandon D.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-23",
        "review_rate": 5.0,
        "customer_name": "Dr. Lisa Coleman MD",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Nathan E.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.9,
        "customer_name": "Craig Mitchell",
        "service_type": "Garage Door Off Track",
        "tech_name": "Cindy M.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.9,
        "customer_name": "Mark Booker",
        "service_type": "Garage Door Opener",
        "tech_name": "Melinda L.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.9,
        "customer_name": "William Hartman",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Dylan G.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.9,
        "customer_name": "Kim Mitchell",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Daniel W.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.9,
        "customer_name": "Christopher Jones",
        "service_type": "Garage Door Insulation",
        "tech_name": "Autumn S.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.8,
        "customer_name": "Jeremy Stokes",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "John R.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.9,
        "customer_name": "Jonathan Conley",
        "service_type": "Garage Door Off Track",
        "tech_name": "David C.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Henry Thomas",
        "service_type": "Garage Door Insulation",
        "tech_name": "Marcus F.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.9,
        "customer_name": "David Park",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Sarah W.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.9,
        "customer_name": "Scott Thompson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "David L.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.9,
        "customer_name": "Jeffery Hopkins",
        "service_type": "Garage Door Insulation",
        "tech_name": "Elizabeth R.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Evan Porter",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Melissa N.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-29",
        "review_rate": 5.0,
        "customer_name": "Wyatt Moses",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Anthony A.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.8,
        "customer_name": "Kristine Faulkner",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Erica H.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.9,
        "customer_name": "Brandon Orr",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Lori M.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.9,
        "customer_name": "Natalie Wade",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "David W.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-09",
        "review_rate": 5.0,
        "customer_name": "George Abbott",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Raymond J.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.8,
        "customer_name": "John Calderon",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jamie M.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.9,
        "customer_name": "Mckenzie Ballard",
        "service_type": "Garage Door Installation",
        "tech_name": "Gregory B.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.9,
        "customer_name": "Katrina Johnson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Holly C.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.8,
        "customer_name": "William Little",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Amy W.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Gabriel Smith",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Christine M.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.9,
        "customer_name": "Nicole Nelson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Isaiah B.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Connie Hill",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Benjamin B.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.9,
        "customer_name": "Dana Fields",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Bryan T.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.9,
        "customer_name": "Tina Wright",
        "service_type": "Garage Door Repair",
        "tech_name": "Phillip G.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.9,
        "customer_name": "Nicholas Hall",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Michael C.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-21",
        "review_rate": 5.0,
        "customer_name": "Cory Sanchez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Benjamin O.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-16",
        "review_rate": 5.0,
        "customer_name": "Ryan Elliott",
        "service_type": "Garage Door Repair",
        "tech_name": "Sonya C.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-22",
        "review_rate": 5.0,
        "customer_name": "Rebecca Hanson",
        "service_type": "Garage Door Services",
        "tech_name": "Rebecca G.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.9,
        "customer_name": "Ann Rodriguez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Michael S.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.8,
        "customer_name": "Christopher Jefferson",
        "service_type": "Garage Door Services",
        "tech_name": "Luis F.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-14",
        "review_rate": 5.0,
        "customer_name": "Kelly Powers",
        "service_type": "Garage Door Off Track",
        "tech_name": "Brittany C.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.9,
        "customer_name": "Daniel White",
        "service_type": "Garage Door Repair",
        "tech_name": "Adrian S.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.9,
        "customer_name": "Daniel Webb",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jessica S.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-09",
        "review_rate": 5.0,
        "customer_name": "Jeffrey Martin",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "John D.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-24",
        "review_rate": 5.0,
        "customer_name": "Tony Wright",
        "service_type": "Garage Door Insulation",
        "tech_name": "Nicholas C.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-09",
        "review_rate": 5.0,
        "customer_name": "Catherine Hall",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Lisa A.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.9,
        "customer_name": "Shannon Harris",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Laura B.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.8,
        "customer_name": "Joseph Reed",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Brandon B.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-01",
        "review_rate": 4.8,
        "customer_name": "Emily Wells",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kelsey M.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Ashley Zimmerman",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Rachel P.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-24",
        "review_rate": 5.0,
        "customer_name": "Zachary Garcia",
        "service_type": "Garage Door Installation",
        "tech_name": "Michelle C.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-14",
        "review_rate": 5.0,
        "customer_name": "Mark Gonzalez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Justin C.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.8,
        "customer_name": "Sara Hayes",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Makayla B.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Sarah Vega",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Tammy C.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-13",
        "review_rate": 5.0,
        "customer_name": "Angela Gardner",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Michael H.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.9,
        "customer_name": "Robin Collier MD",
        "service_type": "Garage Door Services",
        "tech_name": "Jared J.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.8,
        "customer_name": "Manuel Howard",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Martha H.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.9,
        "customer_name": "Robert Andrews",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Nicholas L.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.8,
        "customer_name": "Erica Gibson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Gary J.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.9,
        "customer_name": "Ryan Baker",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Robert W.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Deanna Reyes",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Tracy A.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.9,
        "customer_name": "Lisa Bailey",
        "service_type": "Garage Door Opener",
        "tech_name": "Brandy M.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-23",
        "review_rate": 5.0,
        "customer_name": "Kyle Stewart",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Catherine M.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.8,
        "customer_name": "Gabriel Powell",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Cathy A.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Campbell",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jamie P.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.9,
        "customer_name": "Christopher Sawyer",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Angela M.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.8,
        "customer_name": "Kristopher Ibarra",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Matthew R.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.9,
        "customer_name": "Amy Mitchell",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Michael G.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.9,
        "customer_name": "Steven Hatfield",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jessica L.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.9,
        "customer_name": "Helen Gilbert",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Christopher L.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.8,
        "customer_name": "Nicholas Holmes",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jeffrey W.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-24",
        "review_rate": 5.0,
        "customer_name": "Amanda Rodriguez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jennifer Y.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.8,
        "customer_name": "Mike Singleton",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jesse B.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.9,
        "customer_name": "Bobby Johnson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Gary L.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Edward Martin",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Joseph T.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.8,
        "customer_name": "George Myers",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kristina H.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "Kathryn Davis",
        "service_type": "Garage Door Opener",
        "tech_name": "James C.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.9,
        "customer_name": "Mrs. Stephanie Johnson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Yolanda V.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.8,
        "customer_name": "Kimberly Tucker",
        "service_type": "Garage Door Services",
        "tech_name": "Lisa L.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-31",
        "review_rate": 5.0,
        "customer_name": "Angela Cannon",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Michelle B.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.9,
        "customer_name": "William Wallace",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Grace H.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.8,
        "customer_name": "Gary Drake",
        "service_type": "Garage Door Off Track",
        "tech_name": "Christie S.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.8,
        "customer_name": "Olivia Espinoza",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Amanda S.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.9,
        "customer_name": "Nicole Cooley",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Joshua C.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-09",
        "review_rate": 5.0,
        "customer_name": "Courtney Gomez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Joshua M.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Melinda Chapman",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Cheryl W.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.8,
        "customer_name": "Mr. Matthew White II",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Leslie L.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.9,
        "customer_name": "Debra Lewis",
        "service_type": "Garage Door Opener",
        "tech_name": "Antonio B.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "Jessica Robinson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Vincent Y.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-29",
        "review_rate": 5.0,
        "customer_name": "Tom Jenkins",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Ryan H.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.9,
        "customer_name": "Mikayla Johnson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Tanya D.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.9,
        "customer_name": "Brian Hunter",
        "service_type": "Garage Door Insulation",
        "tech_name": "Karen L.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-23",
        "review_rate": 5.0,
        "customer_name": "Barbara Davis",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Briana H.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-23",
        "review_rate": 5.0,
        "customer_name": "Emily Wood",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Edward M.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.9,
        "customer_name": "Jennifer Gross",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Maria G.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Norma Aguilar",
        "service_type": "Garage Door Installation",
        "tech_name": "Sarah S.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.9,
        "customer_name": "Julie Miller",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "William B.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.8,
        "customer_name": "Morgan Garza",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Debra F.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.9,
        "customer_name": "Robert Allison",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jon J.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.8,
        "customer_name": "Kimberly Rhodes",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Benjamin R.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-19",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Rivera",
        "service_type": "Garage Door Opener",
        "tech_name": "Kristina B.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.8,
        "customer_name": "Paula Crane",
        "service_type": "Garage Door Repair",
        "tech_name": "Jason M.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-30",
        "review_rate": 5.0,
        "customer_name": "David Weaver",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Suzanne C.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.9,
        "customer_name": "Stephanie Roberts",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Samantha R.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "Wesley Carpenter",
        "service_type": "Garage Door Opener",
        "tech_name": "Dylan G.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.8,
        "customer_name": "Alejandra Barry",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Cynthia A.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "Brian Schmidt",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Tiffany H.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-31",
        "review_rate": 5.0,
        "customer_name": "Matthew Pineda",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Debra M.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.8,
        "customer_name": "Sheri Hall",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Matthew S.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.9,
        "customer_name": "Laura Perkins",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jessica R.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Carol Hernandez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jillian G.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.9,
        "customer_name": "Chad Davis",
        "service_type": "Garage Door Repair",
        "tech_name": "Raymond S.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.9,
        "customer_name": "Ann Flowers",
        "service_type": "Garage Door Installation",
        "tech_name": "Frank A.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.8,
        "customer_name": "Matthew Ingram",
        "service_type": "Garage Door Repair",
        "tech_name": "Nicholas S.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Karen Warner",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Christian W.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-26",
        "review_rate": 5.0,
        "customer_name": "Melinda Morse",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Michelle M.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.8,
        "customer_name": "Matthew Wilson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Cody G.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.9,
        "customer_name": "Jeff Hughes",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Anna G.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-13",
        "review_rate": 5.0,
        "customer_name": "Lauren Howell",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Frank W.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-16",
        "review_rate": 5.0,
        "customer_name": "Dennis Norman",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "James H.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.8,
        "customer_name": "Stephen Weeks",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Dawn G.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "Jeremy Coleman",
        "service_type": "Garage Door Insulation",
        "tech_name": "Thomas B.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Tonya Fletcher",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Robert N.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.9,
        "customer_name": "Erin Foley",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Darrell J.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.8,
        "customer_name": "Sarah Carter",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Miguel M.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.8,
        "customer_name": "Michelle Harper",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Linda F.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.8,
        "customer_name": "Elaine Gaines",
        "service_type": "Garage Door Opener",
        "tech_name": "John K.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-28",
        "review_rate": 5.0,
        "customer_name": "Christopher Howell",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Gregory S.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.9,
        "customer_name": "Jordan Sharp",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Todd S.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-27",
        "review_rate": 5.0,
        "customer_name": "Megan Macias",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Tony R.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-21",
        "review_rate": 5.0,
        "customer_name": "Johnny Blackburn",
        "service_type": "Garage Door Installation",
        "tech_name": "Allison B.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.8,
        "customer_name": "Molly Page",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Natalie G.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.8,
        "customer_name": "Christopher Garcia PhD",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Amy S.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.8,
        "customer_name": "Beverly Murphy",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Melissa B.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.8,
        "customer_name": "Loretta Campos MD",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Michelle S.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Charles Cruz MD",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Kimberly M.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.9,
        "customer_name": "Anne Miller",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Brian T.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.9,
        "customer_name": "Joshua Johnson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Shane B.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "Samuel Lawson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Stephanie B.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.9,
        "customer_name": "Austin Howard",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Gregory O.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.9,
        "customer_name": "Cameron Williams",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Ryan W.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Monica Wilson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Erika H.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.9,
        "customer_name": "Alexander Hernandez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Carolyn S.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.9,
        "customer_name": "Rebecca Brown",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "April P.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.9,
        "customer_name": "Henry Reed",
        "service_type": "Garage Door Insulation",
        "tech_name": "Trevor S.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.8,
        "customer_name": "Jordan Villanueva",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Chris W.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.9,
        "customer_name": "Tracy Miller",
        "service_type": "Garage Door Insulation",
        "tech_name": "Juan S.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.9,
        "customer_name": "Madison Mahoney",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jonathan C.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-15",
        "review_rate": 5.0,
        "customer_name": "Miranda Nichols",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Ronald L.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-05",
        "review_rate": 5.0,
        "customer_name": "Ronald Barber",
        "service_type": "Garage Door Repair",
        "tech_name": "Betty H.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.8,
        "customer_name": "Michelle Clark",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Penny J.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.9,
        "customer_name": "Connor Brown",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Nicole A.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Shannon Watts",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jeremy M.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-19",
        "review_rate": 5.0,
        "customer_name": "Jodi Allen",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Clinton H.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Jennifer Chapman",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Olivia G.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.9,
        "customer_name": "Dana Williams",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jeffrey M.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-09",
        "review_rate": 5.0,
        "customer_name": "Danielle Phillips",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Ronald W.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-19",
        "review_rate": 5.0,
        "customer_name": "Jeffrey Woods",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Walter A.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "Kyle Bray",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Joshua R.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-03",
        "review_rate": 5.0,
        "customer_name": "Margaret Martin",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Gloria C.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.9,
        "customer_name": "Tonya Brown",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jason M.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-26",
        "review_rate": 5.0,
        "customer_name": "Randy Cole",
        "service_type": "Garage Door Repair",
        "tech_name": "Calvin C.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-02",
        "review_rate": 5.0,
        "customer_name": "Christine Collins",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Cassidy B.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-22",
        "review_rate": 4.9,
        "customer_name": "Daniel Mitchell",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kristina S.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.9,
        "customer_name": "Joshua Cook Jr.",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Steven R.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.9,
        "customer_name": "Sean Hutchinson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Donna H.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.9,
        "customer_name": "Larry Todd",
        "service_type": "Garage Door Installation",
        "tech_name": "Jacob B.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-12",
        "review_rate": 5.0,
        "customer_name": "Andrew Jackson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Robert G.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.9,
        "customer_name": "Tonya Williamson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Chris L.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.8,
        "customer_name": "Thomas Wilson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Elizabeth S.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.9,
        "customer_name": "Shawn Garza",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Dylan P.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.8,
        "customer_name": "Richard Macdonald",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Scott S.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-24",
        "review_rate": 5.0,
        "customer_name": "David Lewis",
        "service_type": "Garage Door Installation",
        "tech_name": "Lawrence D.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.9,
        "customer_name": "Andres Johnson MD",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Brad D.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Katherine Blevins",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Joseph M.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.8,
        "customer_name": "Dennis Smith",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Andrea F.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.9,
        "customer_name": "Sandy Lee",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jessica A.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.9,
        "customer_name": "William Moore",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Michael R.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Carolyn Nicholson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Michael M.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.8,
        "customer_name": "Jessica Wilson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Lindsey M.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.8,
        "customer_name": "Brittany Castillo",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Matthew V.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.8,
        "customer_name": "Tina Roberts",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Rhonda W.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.9,
        "customer_name": "Timothy Turner",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jessica T.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.8,
        "customer_name": "James Perez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Joshua H.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.9,
        "customer_name": "William Hooper",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Debra N.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-10",
        "review_rate": 5.0,
        "customer_name": "Carolyn Lopez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jennifer C.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.9,
        "customer_name": "Jennifer Murray",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Danielle A.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.9,
        "customer_name": "Brandon Davis",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Richard O.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Harold Calhoun",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "William P.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.9,
        "customer_name": "Gerald Velazquez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "David M.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-01",
        "review_rate": 5.0,
        "customer_name": "Emma Bright",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Heidi P.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.9,
        "customer_name": "Robin Lee",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Emily W.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.9,
        "customer_name": "Kevin Rodriguez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kristin M.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.8,
        "customer_name": "Richard Morrison",
        "service_type": "Garage Door Insulation",
        "tech_name": "David J.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "John Moody",
        "service_type": "Garage Door Installation",
        "tech_name": "Jaime S.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-18",
        "review_rate": 5.0,
        "customer_name": "James Schmidt",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Theresa N.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.9,
        "customer_name": "Alexis Wiley",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Miguel J.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.9,
        "customer_name": "Brian Thompson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Frank M.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.8,
        "customer_name": "Caleb Wilson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Cameron B.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-04",
        "review_rate": 5.0,
        "customer_name": "Edward Smith",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Travis S.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-10",
        "review_rate": 5.0,
        "customer_name": "Whitney Smith",
        "service_type": "Garage Door Services",
        "tech_name": "Gregory T.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.9,
        "customer_name": "Rachel Davis",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Melinda S.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-13",
        "review_rate": 5.0,
        "customer_name": "Jennifer Reeves DDS",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Daniel V.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.8,
        "customer_name": "Jeremy Matthews",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Diane G.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-09",
        "review_rate": 5.0,
        "customer_name": "Neil Chapman",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Brandon M.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.8,
        "customer_name": "Lori Young",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Elizabeth G.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.8,
        "customer_name": "Mary Kelly",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kenneth F.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.8,
        "customer_name": "James Carrillo",
        "service_type": "Garage Door Repair",
        "tech_name": "Brooke R.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-12",
        "review_rate": 5.0,
        "customer_name": "Samantha Scott",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Richard S.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.8,
        "customer_name": "Shannon Villanueva",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Rachel O.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Matthew Anderson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Mike W.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.8,
        "customer_name": "Mary Mahoney",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Richard E.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.8,
        "customer_name": "Kristin Nguyen",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Pamela C.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Jeffery Potter",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Catherine R.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "Christopher Young",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Robert B.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Sandra Anderson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Brett H.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-16",
        "review_rate": 5.0,
        "customer_name": "Isabel Rhodes",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Marvin W.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Anthony Ingram",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Megan G.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-24",
        "review_rate": 5.0,
        "customer_name": "Erin Lewis",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "David K.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.8,
        "customer_name": "Michelle Pennington",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "David S.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.9,
        "customer_name": "Brian Jordan",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jose S.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Michael Parker",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Vanessa T.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.9,
        "customer_name": "Dr. Mary Allen",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jose C.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.8,
        "customer_name": "Erin Moore",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jessica B.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.8,
        "customer_name": "Angela Phillips",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Nathaniel B.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.9,
        "customer_name": "Jacob Peters",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Catherine R.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-12",
        "review_rate": 5.0,
        "customer_name": "Christopher Lee",
        "service_type": "Garage Door Installation",
        "tech_name": "Tonya C.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.8,
        "customer_name": "Carlos Fields",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Corey M.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "Karen Petersen",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Natasha S.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.8,
        "customer_name": "Angela Small",
        "service_type": "Garage Door Off Track",
        "tech_name": "Ian A.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.8,
        "customer_name": "Timothy May",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Michael G.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.9,
        "customer_name": "Nicole Martinez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jenny M.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-11",
        "review_rate": 5.0,
        "customer_name": "Stephanie Jimenez",
        "service_type": "Garage Door Repair",
        "tech_name": "Cory H.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-29",
        "review_rate": 5.0,
        "customer_name": "Jennifer Miles",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Stephen M.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.9,
        "customer_name": "Karen Reilly",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Thomas M.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.9,
        "customer_name": "Lindsey Schroeder",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Deborah C.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.9,
        "customer_name": "Christopher Burke",
        "service_type": "Garage Door Off Track",
        "tech_name": "Darren W.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "Amanda Perez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Alan D.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.9,
        "customer_name": "Tyler Williams",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "David S.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.9,
        "customer_name": "Jessica Harper",
        "service_type": "Garage Door Off Track",
        "tech_name": "Joseph H.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.8,
        "customer_name": "James Ray",
        "service_type": "Garage Door Insulation",
        "tech_name": "Joe M.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.9,
        "customer_name": "Sean Benjamin",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Cathy W.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.8,
        "customer_name": "Lacey Mitchell",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Patrick G.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.9,
        "customer_name": "Katrina Meadows",
        "service_type": "Garage Door Insulation",
        "tech_name": "Darlene S.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-08",
        "review_rate": 5.0,
        "customer_name": "Trevor Harris",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Thomas S.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-10",
        "review_rate": 5.0,
        "customer_name": "Adam Spencer",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Wendy S.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.8,
        "customer_name": "Andrew Perez",
        "service_type": "Garage Door Services",
        "tech_name": "Jane S.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.9,
        "customer_name": "Kimberly Reeves",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Steven G.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.8,
        "customer_name": "Jerry Hendrix",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kaitlyn K.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-15",
        "review_rate": 5.0,
        "customer_name": "Robert Rose",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Renee S.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-30",
        "review_rate": 5.0,
        "customer_name": "Charles Smith",
        "service_type": "Garage Door Installation",
        "tech_name": "Stephen B.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.9,
        "customer_name": "Christopher Stout",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Matthew C.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-17",
        "review_rate": 5.0,
        "customer_name": "John Martin",
        "service_type": "Garage Door Repair",
        "tech_name": "Kimberly W.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "David Sherman",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Aaron D.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Julie Obrien",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "David P.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-08",
        "review_rate": 5.0,
        "customer_name": "Jesus Martin",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Lisa W.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.9,
        "customer_name": "Lisa Walsh",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Larry S.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "Kelly Cooper",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Ann P.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-12",
        "review_rate": 5.0,
        "customer_name": "Ashley Wagner",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Michael W.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.8,
        "customer_name": "Nicole Nash",
        "service_type": "Garage Door Off Track",
        "tech_name": "Lori N.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.9,
        "customer_name": "Mary Reynolds",
        "service_type": "Residential Garage Door Services",
        "tech_name": "James R.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.8,
        "customer_name": "Bryan Wilson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Julia R.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Scott Crosby",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Erika F.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Rogers",
        "service_type": "Garage Door Installation",
        "tech_name": "Sara S.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.9,
        "customer_name": "Stefanie Leblanc",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jonathan W.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.9,
        "customer_name": "Robert Kane",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Daniel M.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.9,
        "customer_name": "Jonathan Deleon",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Kyle H.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.9,
        "customer_name": "Emily Jimenez",
        "service_type": "Garage Door Services",
        "tech_name": "James H.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.9,
        "customer_name": "Maria Holt",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Matthew P.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-12",
        "review_rate": 5.0,
        "customer_name": "Laura Johnson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "David H.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-10",
        "review_rate": 5.0,
        "customer_name": "Patty Skinner DDS",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Taylor H.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.9,
        "customer_name": "Anthony Copeland",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Brandi T.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.8,
        "customer_name": "John Welch",
        "service_type": "Garage Door Installation",
        "tech_name": "Karen H.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.8,
        "customer_name": "Kelly Davis",
        "service_type": "Garage Door Services",
        "tech_name": "Taylor P.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.9,
        "customer_name": "Julie Hanna",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Melissa K.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.9,
        "customer_name": "Larry Gilbert",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "David B.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.9,
        "customer_name": "Jessica Lara",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Darin Z.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-05",
        "review_rate": 5.0,
        "customer_name": "John Stark",
        "service_type": "Garage Door Repair",
        "tech_name": "William W.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.9,
        "customer_name": "Peter Clark",
        "service_type": "Garage Door Repair",
        "tech_name": "Seth S.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-19",
        "review_rate": 5.0,
        "customer_name": "Marcus Wilson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Sheryl P.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.9,
        "customer_name": "Christopher Martinez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Carol C.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Alyssa Pope",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Kevin F.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Rhonda Leonard",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Todd R.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Sarah Clarke",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "David S.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.9,
        "customer_name": "Jo Short",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Casey S.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-17",
        "review_rate": 5.0,
        "customer_name": "Michael Scott",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jessica W.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-28",
        "review_rate": 5.0,
        "customer_name": "Angela Young",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Eugene L.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-13",
        "review_rate": 5.0,
        "customer_name": "Susan Finley",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Joseph B.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.9,
        "customer_name": "Brenda Nelson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Theresa P.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.9,
        "customer_name": "Alexander Ford",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Scott D.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.9,
        "customer_name": "Jennifer Patterson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Michael S.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.8,
        "customer_name": "David Morgan",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jason P.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.9,
        "customer_name": "Sara Johnson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Stephen B.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.8,
        "customer_name": "Brianna Robinson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Kyle E.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-06",
        "review_rate": 5.0,
        "customer_name": "Tara Fleming",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Latoya W.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.8,
        "customer_name": "Kristen Hansen",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kenneth S.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Timothy Warren",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jacob C.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.9,
        "customer_name": "Carolyn Brown",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Lance W.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.8,
        "customer_name": "Lee Williams",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Linda L.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.9,
        "customer_name": "Julia Wise",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Cheryl M.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-28",
        "review_rate": 5.0,
        "customer_name": "Sarah Smith",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Rebecca H.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.8,
        "customer_name": "Cheryl Cummings",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Sarah H.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Sarah Martinez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jason C.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.9,
        "customer_name": "Angela Parker",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Bonnie D.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.9,
        "customer_name": "John Morgan",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jessica H.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.8,
        "customer_name": "Jack Williams",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jacqueline R.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Jessica Hall",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kimberly A.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.9,
        "customer_name": "Lindsey Riley",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Susan B.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-22",
        "review_rate": 5.0,
        "customer_name": "Ryan Dyer",
        "service_type": "Residential Garage Door Services",
        "tech_name": "David M.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.9,
        "customer_name": "Heather Cantrell",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jose A.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-06",
        "review_rate": 5.0,
        "customer_name": "Jennifer Nguyen",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Michael M.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.9,
        "customer_name": "Robert Patterson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Rachel M.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.8,
        "customer_name": "Emma Schwartz",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jill L.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-09-28",
        "review_rate": 5.0,
        "customer_name": "Stephen Lynch",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jessica R.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.8,
        "customer_name": "Becky Meyer",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Dennis B.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-03",
        "review_rate": 5.0,
        "customer_name": "Brenda Newman",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Alyssa P.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.9,
        "customer_name": "Lonnie Atkinson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Alexander W.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.8,
        "customer_name": "Joann Willis",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Peter G.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.9,
        "customer_name": "Darlene Galloway",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "John B.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.8,
        "customer_name": "Heather Christensen",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Damon B.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.9,
        "customer_name": "Nathan Parsons",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Sarah P.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.8,
        "customer_name": "Jonathon Williams",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jonathan H.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-16",
        "review_rate": 5.0,
        "customer_name": "Chad Thompson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "John H.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Natalie Ochoa",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Brandon B.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.8,
        "customer_name": "Sara Griffin",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Bruce B.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.9,
        "customer_name": "Lance Brown",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Steven A.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.9,
        "customer_name": "Tony Klein",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Michelle S.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.8,
        "customer_name": "Daniel Obrien",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Margaret S.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.9,
        "customer_name": "Paul Schultz",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Stephen B.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Tara Farmer",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Ronald B.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.8,
        "customer_name": "Julie Ortiz",
        "service_type": "Garage Door Installation",
        "tech_name": "Henry B.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.8,
        "customer_name": "John Kennedy",
        "service_type": "Garage Door Repair",
        "tech_name": "Joyce W.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Hernandez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kimberly J.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.8,
        "customer_name": "Ricky Evans",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Laura G.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.8,
        "customer_name": "Alexander Davis",
        "service_type": "Garage Door Off Track",
        "tech_name": "Nicole M.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.9,
        "customer_name": "Jennifer Frank",
        "service_type": "Garage Door Installation",
        "tech_name": "Timothy G.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-15",
        "review_rate": 5.0,
        "customer_name": "Christopher Carr",
        "service_type": "Garage Door Opener",
        "tech_name": "Beverly B.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.9,
        "customer_name": "Laurie Williams",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Lisa B.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "David Gray",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Christina K.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.8,
        "customer_name": "Garrett Francis",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Katie M.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.9,
        "customer_name": "Amanda Taylor",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Elizabeth P.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.9,
        "customer_name": "Donald Harris",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Cynthia L.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.9,
        "customer_name": "Justin Collier",
        "service_type": "Garage Door Track Repair",
        "tech_name": "David W.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.8,
        "customer_name": "Mark King",
        "service_type": "Garage Door Services",
        "tech_name": "Jason L.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.8,
        "customer_name": "Ralph Palmer",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Tyler C.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.9,
        "customer_name": "Thomas Richardson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "John J.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Shawn Horton",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Martin K.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Sara Bailey",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Richard P.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "Sherri Graham",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Thomas C.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.9,
        "customer_name": "Scott Mitchell",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Ashley C.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.8,
        "customer_name": "Natasha Ruiz",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Brandon L.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.9,
        "customer_name": "Michael Watson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Randall W.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.9,
        "customer_name": "Kristie Williams PhD",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Sandra J.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-24",
        "review_rate": 5.0,
        "customer_name": "Sarah Turner",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Frances W.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.8,
        "customer_name": "James Harvey",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Tony A.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-27",
        "review_rate": 5.0,
        "customer_name": "Christy Jacobs",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Erin B.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.8,
        "customer_name": "Gloria Evans MD",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Stephanie M.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-09-30",
        "review_rate": 5.0,
        "customer_name": "Frank Hernandez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Christopher W.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.9,
        "customer_name": "Christine Hudson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Rachel H.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.9,
        "customer_name": "Zachary Merritt",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Melissa L.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.9,
        "customer_name": "Amanda Campbell",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kimberly M.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "Michael Smith",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Cassidy D.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.9,
        "customer_name": "Rebecca Johnson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Andrew P.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-16",
        "review_rate": 5.0,
        "customer_name": "David Romero",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Rachel L.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.9,
        "customer_name": "Christopher Peters",
        "service_type": "Garage Door Off Track",
        "tech_name": "Debra P.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.9,
        "customer_name": "Theresa Lane",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Adam B.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.9,
        "customer_name": "Brian Lewis",
        "service_type": "Garage Door Services",
        "tech_name": "Wendy R.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.8,
        "customer_name": "Patrick Richards",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Nicholas M.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.8,
        "customer_name": "Martin Sullivan",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Melissa H.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.8,
        "customer_name": "Ruth Thomas",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Stephen B.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.9,
        "customer_name": "Lawrence Little",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Beth R.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.9,
        "customer_name": "Joanna Chang",
        "service_type": "Garage Door Insulation",
        "tech_name": "Gregg Y.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.8,
        "customer_name": "James Bradford",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Stephen H.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Daniel Smith",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kim R.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.8,
        "customer_name": "Cody Howard",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Donna T.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "Adrienne Edwards",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Mark D.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "Mr. Jason Ryan MD",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Brian W.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.8,
        "customer_name": "Robert Jones",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "William C.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.9,
        "customer_name": "Frank Oconnor",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Justin C.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "Alvin Berry",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Barbara B.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Dawn Patrick",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Olivia M.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "Leslie Lawrence",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Michael H.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.9,
        "customer_name": "Andrea Walker",
        "service_type": "Garage Door Insulation",
        "tech_name": "Emily O.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-31",
        "review_rate": 5.0,
        "customer_name": "Thomas Johnson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Nathan F.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.8,
        "customer_name": "Antonio Brady",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Emily W.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.9,
        "customer_name": "Ian West",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Monica M.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-10",
        "review_rate": 5.0,
        "customer_name": "Michael Turner",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Scott R.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-01",
        "review_rate": 4.9,
        "customer_name": "Hannah Montgomery",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Austin C.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.8,
        "customer_name": "Nicole Taylor",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Devon B.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Debra Morales",
        "service_type": "Garage Door Opener",
        "tech_name": "David S.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Kristen Thomas",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Amanda L.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-04",
        "review_rate": 5.0,
        "customer_name": "Andrea Williams",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jamie G.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Becky Malone",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Samantha M.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-09",
        "review_rate": 5.0,
        "customer_name": "Robert Mccoy",
        "service_type": "Garage Door Opener",
        "tech_name": "Robert B.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Crystal Rangel",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Gary M.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-03",
        "review_rate": 5.0,
        "customer_name": "Whitney Stevens",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Brittany R.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.9,
        "customer_name": "Raymond Gardner",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Anthony M.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-18",
        "review_rate": 5.0,
        "customer_name": "Sherry Rodriguez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Alexander L.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.9,
        "customer_name": "Cindy Smith",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Brandon F.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.9,
        "customer_name": "Colleen Oliver",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kevin D.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.9,
        "customer_name": "Audrey Rodriguez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Richard D.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.9,
        "customer_name": "Amy Thompson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Robert G.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.8,
        "customer_name": "Paul Ward",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Tracey W.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.9,
        "customer_name": "Sara Mcfarland",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Debra P.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.9,
        "customer_name": "Debbie Goodman",
        "service_type": "Garage Door Repair",
        "tech_name": "Victoria H.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.8,
        "customer_name": "Nancy Mitchell",
        "service_type": "Garage Door Installation",
        "tech_name": "Abigail H.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.9,
        "customer_name": "Cody Ortiz",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Ryan H.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "Jody Huynh",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Evelyn M.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-06",
        "review_rate": 5.0,
        "customer_name": "Christopher Gross",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Timothy M.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.9,
        "customer_name": "Kenneth Hall",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Justin S.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.9,
        "customer_name": "Matthew Martinez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jessica L.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.9,
        "customer_name": "Danielle Brown",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Beth M.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-31",
        "review_rate": 5.0,
        "customer_name": "Shelby Bell",
        "service_type": "Garage Door Services",
        "tech_name": "Adam S.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.9,
        "customer_name": "Kevin Alexander",
        "service_type": "Garage Door Off Track",
        "tech_name": "Daniel W.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-21",
        "review_rate": 5.0,
        "customer_name": "Robert Bailey",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Audrey S.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.9,
        "customer_name": "Dennis Gaines",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Shawn Z.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "Sandra Butler",
        "service_type": "Garage Door Installation",
        "tech_name": "Brian S.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-11",
        "review_rate": 5.0,
        "customer_name": "Lisa Pugh",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Shannon V.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.8,
        "customer_name": "Anthony Palmer",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Robert R.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.9,
        "customer_name": "Terry Terry",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Reginald A.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "Olivia Hamilton",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Ethan D.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-30",
        "review_rate": 5.0,
        "customer_name": "Tiffany Herman",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Wendy M.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "Alexander Rodriguez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Justin M.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.9,
        "customer_name": "Micheal Osborn",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Lauren M.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.9,
        "customer_name": "Stacey Davis",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "William W.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "Scott Smith",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Alexandra B.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.9,
        "customer_name": "Sara Williams",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kimberly S.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-01",
        "review_rate": 5.0,
        "customer_name": "Natasha Moreno",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jennifer G.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.8,
        "customer_name": "Melinda Chang",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Brandon O.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "Chase Gonzalez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Aaron E.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "Anthony Spencer",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Alejandra C.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.8,
        "customer_name": "Crystal Evans",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Joshua R.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.9,
        "customer_name": "James Little",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jeremy A.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.9,
        "customer_name": "Tammy Ramos",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Maria W.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.9,
        "customer_name": "Sean Kirby",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jessica H.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-19",
        "review_rate": 5.0,
        "customer_name": "Michelle Flores",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jane E.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.9,
        "customer_name": "Maxwell Hale",
        "service_type": "Garage Door Installation",
        "tech_name": "Derrick M.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Francis Klein",
        "service_type": "Garage Door Repair",
        "tech_name": "Regina B.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.9,
        "customer_name": "Bobby Carter DVM",
        "service_type": "Garage Door Opener",
        "tech_name": "Matthew P.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-09-29",
        "review_rate": 5.0,
        "customer_name": "Margaret Nichols",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Kristen C.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-23",
        "review_rate": 5.0,
        "customer_name": "Shannon Roberts",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Larry P.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.9,
        "customer_name": "Natalie Brown",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Juan W.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Charles Jenkins",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Paul S.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.9,
        "customer_name": "Katherine Jones",
        "service_type": "Garage Door Insulation",
        "tech_name": "Linda S.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Robert Miles",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jeffery M.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Brittney Price",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Melissa D.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.9,
        "customer_name": "Catherine Juarez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Anna P.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Walter Knight",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Edward D.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-22",
        "review_rate": 5.0,
        "customer_name": "Daniel Stafford",
        "service_type": "Garage Door Insulation",
        "tech_name": "David B.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "Willie Chapman",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Shawn W.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "Shelley Clark",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Shawn R.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.9,
        "customer_name": "David Perez",
        "service_type": "Garage Door Opener",
        "tech_name": "Carl M.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.9,
        "customer_name": "Linda Vasquez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jeremy S.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.8,
        "customer_name": "Mr. Tony Perez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Travis J.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.9,
        "customer_name": "Chad Golden",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Nathan S.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-25",
        "review_rate": 5.0,
        "customer_name": "Aaron Williams",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Alexis J.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.9,
        "customer_name": "Aaron Ward",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Heather C.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.9,
        "customer_name": "William Jackson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Brian T.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Sarah Jones",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Maurice R.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.8,
        "customer_name": "Monica Thompson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Max L.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-21",
        "review_rate": 5.0,
        "customer_name": "Aaron Stein",
        "service_type": "Garage Door Services",
        "tech_name": "Rachel W.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.8,
        "customer_name": "Veronica Smith",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Samuel G.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.8,
        "customer_name": "Diana Smith",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Cole C.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-17",
        "review_rate": 5.0,
        "customer_name": "Barbara Calderon",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Alejandra B.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.8,
        "customer_name": "Thomas Stevenson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jessica K.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.9,
        "customer_name": "Jordan Hanson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Mark A.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.8,
        "customer_name": "Kerry Reilly",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Nicholas M.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "David Parks",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Ricky M.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.9,
        "customer_name": "Leah Simmons",
        "service_type": "Garage Door Off Track",
        "tech_name": "Sherry F.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.9,
        "customer_name": "Christopher Cervantes",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Barbara B.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "Joseph Jones",
        "service_type": "Residential Garage Door Services",
        "tech_name": "William S.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.8,
        "customer_name": "Allison Burns",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Raymond G.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Deborah Stout",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Olivia W.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.8,
        "customer_name": "Jennifer Thompson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Allison B.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.8,
        "customer_name": "Robert Mccoy",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Daniel H.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "Matthew Kramer",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jennifer H.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.8,
        "customer_name": "Brian Briggs",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Brittany W.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.8,
        "customer_name": "Stephanie Ray",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Patricia W.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-11",
        "review_rate": 5.0,
        "customer_name": "Cassidy Martinez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Robert B.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.9,
        "customer_name": "Lisa Anderson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Michael T.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.9,
        "customer_name": "Vanessa Spencer",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Charles F.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-07",
        "review_rate": 5.0,
        "customer_name": "David Evans",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Brian K.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.9,
        "customer_name": "Joseph Avila",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Christopher S.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.8,
        "customer_name": "Robyn Cherry",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Kenneth R.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-18",
        "review_rate": 5.0,
        "customer_name": "Alexandra Frank",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jeffery B.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.9,
        "customer_name": "Marie Morales MD",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Stephanie H.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.8,
        "customer_name": "Michael Johnston",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Susan E.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.9,
        "customer_name": "William Hughes",
        "service_type": "Garage Door Off Track",
        "tech_name": "William A.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.8,
        "customer_name": "Kristina Velez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Caleb F.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Logan Jenkins",
        "service_type": "Garage Door Off Track",
        "tech_name": "Erin N.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.9,
        "customer_name": "Arthur Barnett",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Mary D.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.8,
        "customer_name": "James Valencia",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Brendan C.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-22",
        "review_rate": 5.0,
        "customer_name": "James Long",
        "service_type": "Garage Door Services",
        "tech_name": "Sharon D.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "Brendan Thomas",
        "service_type": "Garage Door Installation",
        "tech_name": "Eric B.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.9,
        "customer_name": "Stacy Velez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Melissa S.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.9,
        "customer_name": "Cindy Lopez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Paula G.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.9,
        "customer_name": "Michael Franklin",
        "service_type": "Garage Door Installation",
        "tech_name": "Deborah C.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.9,
        "customer_name": "Madison Grimes",
        "service_type": "Garage Door Off Track",
        "tech_name": "Paul H.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.8,
        "customer_name": "Gina Jenkins",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Frederick R.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "Amanda Mccarty",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Justin C.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-07",
        "review_rate": 5.0,
        "customer_name": "Rachel Hines",
        "service_type": "Garage Door Opener",
        "tech_name": "Andre E.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.8,
        "customer_name": "Aaron Perry",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Ashley K.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.8,
        "customer_name": "Tim White",
        "service_type": "Garage Door Off Track",
        "tech_name": "Samantha R.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.8,
        "customer_name": "Joann Reid",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Catherine S.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.9,
        "customer_name": "Timothy Dodson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Stephen P.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Stephanie Gibson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Lisa E.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Jeremy Hendricks",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kristen C.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.8,
        "customer_name": "Laurie Carrillo",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Kristen H.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-08",
        "review_rate": 5.0,
        "customer_name": "Amy Moran",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kathryn G.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Amanda Miller",
        "service_type": "Garage Door Insulation",
        "tech_name": "Tara J.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-17",
        "review_rate": 5.0,
        "customer_name": "Donna Warner PhD",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Benjamin S.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-16",
        "review_rate": 5.0,
        "customer_name": "Carolyn Bailey",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jennifer B.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.8,
        "customer_name": "Carol Carlson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Shannon C.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Amy Adkins",
        "service_type": "Garage Door Installation",
        "tech_name": "Monica L.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.9,
        "customer_name": "Brandi Jackson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Steven C.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.9,
        "customer_name": "James Dean",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Cristina P.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "Jennifer Valdez MD",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Thomas P.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "Robert Curtis",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Mary K.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Sandra Price",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Cynthia P.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.8,
        "customer_name": "Richard Harrell",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Michael H.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-18",
        "review_rate": 5.0,
        "customer_name": "Mark Ward",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jeremy H.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "Hunter Allen",
        "service_type": "Garage Door Services",
        "tech_name": "Daniel S.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.9,
        "customer_name": "Jordan Hodges",
        "service_type": "Garage Door Insulation",
        "tech_name": "Sara H.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.8,
        "customer_name": "Laurie Lee",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Michael A.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-04",
        "review_rate": 5.0,
        "customer_name": "Kristopher Armstrong",
        "service_type": "Garage Door Repair",
        "tech_name": "Erin M.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.9,
        "customer_name": "Michael Davis",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Katrina D.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-02",
        "review_rate": 5.0,
        "customer_name": "Angela Johnston",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Michael M.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-02",
        "review_rate": 5.0,
        "customer_name": "Jeffery Rice",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Daniel C.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.8,
        "customer_name": "Michael Hawkins",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Rachel B.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.8,
        "customer_name": "Susan Conley",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "David S.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "Daniel Weaver",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Kelly G.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-30",
        "review_rate": 5.0,
        "customer_name": "Jerome Meadows",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Tara S.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.8,
        "customer_name": "Jennifer Smith",
        "service_type": "Garage Door Installation",
        "tech_name": "Andrew J.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-31",
        "review_rate": 5.0,
        "customer_name": "Zachary Saunders",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Samantha T.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.9,
        "customer_name": "Albert Lee",
        "service_type": "Garage Door Off Track",
        "tech_name": "Linda K.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.9,
        "customer_name": "Robert Reed",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Gabriel G.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.9,
        "customer_name": "Dr. David Rogers",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Tammy H.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.9,
        "customer_name": "Jennifer Welch",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Danielle W.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.8,
        "customer_name": "Angela Mcbride",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Christopher S.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Thomas Kramer",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Cody H.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.8,
        "customer_name": "Melvin Jackson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Carla C.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Kenneth Morrison",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Robert E.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-01",
        "review_rate": 5.0,
        "customer_name": "Anthony Gregory",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Caitlin P.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.8,
        "customer_name": "Amber Oneal",
        "service_type": "Garage Door Opener",
        "tech_name": "Steven P.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Becky Robles",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Angela B.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.8,
        "customer_name": "Beth Morgan",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Yolanda D.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-11",
        "review_rate": 5.0,
        "customer_name": "Patrick Franklin",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "William P.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-08",
        "review_rate": 5.0,
        "customer_name": "Nathan Gonzalez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Laura M.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-07",
        "review_rate": 5.0,
        "customer_name": "Victoria James",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Douglas W.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.9,
        "customer_name": "Steven Rodriguez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Amanda W.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-09",
        "review_rate": 5.0,
        "customer_name": "Connie Alvarado",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Edward J.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-18",
        "review_rate": 5.0,
        "customer_name": "Christine Heath",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Kathryn L.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-26",
        "review_rate": 5.0,
        "customer_name": "Amy Ellis",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jeffrey D.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Sarah Edwards",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Walter R.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.8,
        "customer_name": "Ronald Mills",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Justin H.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Jacob Cohen",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Bailey W.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-23",
        "review_rate": 5.0,
        "customer_name": "Angel Wilson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "James R.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.9,
        "customer_name": "Megan Boyer",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Maureen J.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.9,
        "customer_name": "Larry Griffith",
        "service_type": "Garage Door Services",
        "tech_name": "Chelsea M.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-04",
        "review_rate": 5.0,
        "customer_name": "Brandon Grimes",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Adrian T.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.9,
        "customer_name": "Hayden Mccarthy",
        "service_type": "Garage Door Installation",
        "tech_name": "Benjamin W.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-04",
        "review_rate": 5.0,
        "customer_name": "Courtney Lam",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Daniel M.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-21",
        "review_rate": 5.0,
        "customer_name": "Bobby Quinn",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Daniel B.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-06",
        "review_rate": 5.0,
        "customer_name": "Jonathan Payne",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Deborah H.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.9,
        "customer_name": "David Pennington",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Robert M.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-30",
        "review_rate": 5.0,
        "customer_name": "Marcus Ward",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Gina T.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.8,
        "customer_name": "Steven Collins",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Heather C.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-19",
        "review_rate": 5.0,
        "customer_name": "William Martinez",
        "service_type": "Garage Door Services",
        "tech_name": "Shirley R.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.8,
        "customer_name": "Brian Hawkins",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jessica H.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.9,
        "customer_name": "Nicole Meza",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Patrick H.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-06",
        "review_rate": 5.0,
        "customer_name": "Christopher Becker",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Eric C.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.9,
        "customer_name": "Nancy Ray",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Brett B.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "Michael Drake",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Timothy M.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-23",
        "review_rate": 5.0,
        "customer_name": "Lisa Henderson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Amanda M.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-02",
        "review_rate": 5.0,
        "customer_name": "Mikayla Malone",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Tamara L.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.8,
        "customer_name": "Samantha Chapman",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Sherri S.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.8,
        "customer_name": "Brandon Hicks",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Isaac S.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "Robert Johnson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Victoria O.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.9,
        "customer_name": "Jennifer Wilcox",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Julia M.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.9,
        "customer_name": "Deborah Harris",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Edward R.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.9,
        "customer_name": "Mr. Robert Hart",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Ashley F.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-30",
        "review_rate": 5.0,
        "customer_name": "Kimberly Davidson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Robyn D.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.8,
        "customer_name": "Donna Simmons",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Emily L.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.8,
        "customer_name": "Mr. Stephen Flores",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Bryan J.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.8,
        "customer_name": "Juan Davis",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jeremy J.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.9,
        "customer_name": "Gail Hall",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Ian W.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.9,
        "customer_name": "Karen Smith",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Brian C.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "Randy Garrison",
        "service_type": "Garage Door Services",
        "tech_name": "Rodney W.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Scott Clements",
        "service_type": "Garage Door Repair",
        "tech_name": "Bradley A.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.8,
        "customer_name": "William Kane",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Cynthia B.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-12",
        "review_rate": 5.0,
        "customer_name": "David Wilson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Ashley C.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.9,
        "customer_name": "Benjamin Palmer",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Michelle T.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-14",
        "review_rate": 5.0,
        "customer_name": "Barry Winters",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Yvonne W.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.9,
        "customer_name": "Andrew Johnson",
        "service_type": "Garage Door Repair",
        "tech_name": "Peter H.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.9,
        "customer_name": "Jennifer Graves",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Daniel T.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-09",
        "review_rate": 5.0,
        "customer_name": "Marco Porter",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Tonya P.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-03",
        "review_rate": 5.0,
        "customer_name": "Lisa Webb",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Robert C.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.9,
        "customer_name": "Shawn Pollard",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jessica B.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-31",
        "review_rate": 5.0,
        "customer_name": "Anthony Edwards",
        "service_type": "Garage Door Services",
        "tech_name": "Michael L.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.8,
        "customer_name": "Justin Jones",
        "service_type": "Garage Door Opener",
        "tech_name": "Erika M.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-12",
        "review_rate": 5.0,
        "customer_name": "Michaela Jackson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Steven M.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.9,
        "customer_name": "Aaron Miller",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Riley H.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.9,
        "customer_name": "Robert Wise",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Donald P.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-09",
        "review_rate": 5.0,
        "customer_name": "Victor Munoz DDS",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Brittany A.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.8,
        "customer_name": "Rachel Wood",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Haley K.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.8,
        "customer_name": "Ryan Harding",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jerry S.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Austin Hernandez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Mallory B.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.9,
        "customer_name": "Michael Robertson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Margaret W.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.8,
        "customer_name": "Heather Gonzales",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Andrew H.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-22",
        "review_rate": 5.0,
        "customer_name": "Sarah Brown",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kimberly R.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.9,
        "customer_name": "Tony Howard",
        "service_type": "Garage Door Off Track",
        "tech_name": "Gary P.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.8,
        "customer_name": "Jennifer Johnson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Joshua C.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-02",
        "review_rate": 5.0,
        "customer_name": "Adam Shaw",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Cameron W.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Cheyenne Landry",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Daniel B.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Charles Vargas",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Francisco M.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-01",
        "review_rate": 5.0,
        "customer_name": "Lauren Robinson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Maria H.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.9,
        "customer_name": "Isaac Johnson",
        "service_type": "Garage Door Services",
        "tech_name": "Brandon C.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-19",
        "review_rate": 5.0,
        "customer_name": "Sean Johnson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Christopher R.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.9,
        "customer_name": "Jessica Hall",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Joseph C.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.8,
        "customer_name": "Kevin Johnson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jack L.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Catherine Norton",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Margaret H.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Bruce Evans",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Kevin B.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-08",
        "review_rate": 5.0,
        "customer_name": "Victoria Fleming",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Walter T.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "Catherine Robles",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Brent D.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.9,
        "customer_name": "John Brown",
        "service_type": "Garage Door Off Track",
        "tech_name": "Daniel V.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.8,
        "customer_name": "Alex Munoz",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Andrew D.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Michael Williams",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Haley P.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-12",
        "review_rate": 5.0,
        "customer_name": "Joshua Davis",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Tyler F.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.8,
        "customer_name": "Eduardo Becker",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Thomas J.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.9,
        "customer_name": "Dustin Morales",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kristen M.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-07",
        "review_rate": 5.0,
        "customer_name": "Joseph Riddle",
        "service_type": "Garage Door Services",
        "tech_name": "Brian W.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.9,
        "customer_name": "Judy Molina",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jennifer A.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.9,
        "customer_name": "Joseph Pope",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Joseph K.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.9,
        "customer_name": "Janice Wright",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Chad N.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.9,
        "customer_name": "Christy Montoya",
        "service_type": "Garage Door Off Track",
        "tech_name": "Michelle P.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.8,
        "customer_name": "Pamela Rios",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Maria A.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.8,
        "customer_name": "Lisa Rodriguez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Travis R.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.9,
        "customer_name": "Michael Cruz",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jeffrey A.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Stanley",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jason W.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.8,
        "customer_name": "Kerri Cox",
        "service_type": "Garage Door Services",
        "tech_name": "Sylvia W.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.8,
        "customer_name": "Maria Smith",
        "service_type": "Garage Door Opener",
        "tech_name": "Cynthia W.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "John Harper",
        "service_type": "Garage Door Installation",
        "tech_name": "John W.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.9,
        "customer_name": "Tammy Underwood",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Roger F.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.8,
        "customer_name": "Mrs. Bonnie Klein",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jessica Y.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-04",
        "review_rate": 5.0,
        "customer_name": "Barry Moore",
        "service_type": "Garage Door Installation",
        "tech_name": "Dylan M.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-26",
        "review_rate": 5.0,
        "customer_name": "Todd Thomas",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Dawn D.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.9,
        "customer_name": "Michael Sanchez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Tammy M.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-30",
        "review_rate": 5.0,
        "customer_name": "April Huynh",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Michele C.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.9,
        "customer_name": "Karen Miller",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Rebecca S.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.9,
        "customer_name": "Mark Brown",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Lisa P.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.9,
        "customer_name": "Diana Gentry",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Donald F.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Sandra Bridges",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Holly B.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-15",
        "review_rate": 5.0,
        "customer_name": "Sherry Anderson",
        "service_type": "Garage Door Opener",
        "tech_name": "Travis D.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.8,
        "customer_name": "Timothy Johnson",
        "service_type": "Garage Door Installation",
        "tech_name": "Angela R.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-22",
        "review_rate": 5.0,
        "customer_name": "Katie Johnson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Matthew B.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-01",
        "review_rate": 5.0,
        "customer_name": "Rachel Parker",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kevin L.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Kevin Tyler",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Laura M.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Perkins",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Joshua B.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.8,
        "customer_name": "Chad Wall",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Norman R.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.9,
        "customer_name": "Carrie Lee",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Leslie P.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.9,
        "customer_name": "Justin Santos",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Michael D.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-03",
        "review_rate": 5.0,
        "customer_name": "Willie Olson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Andrea R.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.8,
        "customer_name": "Brittany Waters",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Diana B.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.8,
        "customer_name": "Jennifer Duncan",
        "service_type": "Garage Door Services",
        "tech_name": "Heidi S.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-21",
        "review_rate": 5.0,
        "customer_name": "Nancy Robinson MD",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Cheryl L.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.9,
        "customer_name": "Perry Watkins",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Teresa M.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.9,
        "customer_name": "Andrew Alvarado",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kelly D.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.9,
        "customer_name": "Brent Hahn",
        "service_type": "Garage Door Services",
        "tech_name": "Felicia T.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.8,
        "customer_name": "Christian Webb",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Gregory P.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.9,
        "customer_name": "Christopher Daniels",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Megan A.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Donna Maldonado",
        "service_type": "Garage Door Services",
        "tech_name": "Kimberly W.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-03",
        "review_rate": 5.0,
        "customer_name": "Edwin West",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Courtney R.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.9,
        "customer_name": "Melanie Garrett",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Melissa Y.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.9,
        "customer_name": "Michelle Saunders",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Brett S.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.8,
        "customer_name": "Kevin Snyder",
        "service_type": "Garage Door Installation",
        "tech_name": "Virginia W.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-29",
        "review_rate": 5.0,
        "customer_name": "Stephanie Robinson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Elizabeth H.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.9,
        "customer_name": "Ronald Wallace",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Isabel H.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.8,
        "customer_name": "Jessica Miller",
        "service_type": "Garage Door Off Track",
        "tech_name": "Pamela L.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.9,
        "customer_name": "Chelsea Hall",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Taylor G.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "David Richardson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Stacy B.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.9,
        "customer_name": "Jane Bell",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "James W.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-20",
        "review_rate": 5.0,
        "customer_name": "Shannon Johnson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Matthew C.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-05",
        "review_rate": 5.0,
        "customer_name": "Joseph Watson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Laura B.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.9,
        "customer_name": "Yvonne Santiago",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Belinda C.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.8,
        "customer_name": "Dr. Bryan Riley MD",
        "service_type": "Garage Door Insulation",
        "tech_name": "Sarah C.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.8,
        "customer_name": "Vanessa Ross",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Rodney G.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "Michael Jenkins",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Raymond C.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.9,
        "customer_name": "Charles Marks",
        "service_type": "Garage Door Insulation",
        "tech_name": "Joseph M.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "Heather Porter",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Daniel L.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.9,
        "customer_name": "Kaylee Kennedy",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Samantha A.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "Keith Jimenez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Justin T.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.8,
        "customer_name": "James Bradley",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Willie J.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.8,
        "customer_name": "Jamie Morris",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Megan R.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.9,
        "customer_name": "Victoria Sexton",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Erika Z.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-20",
        "review_rate": 5.0,
        "customer_name": "Jennifer Bryan MD",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Donald R.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.9,
        "customer_name": "Jonathan West",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Sandra H.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "Raymond Clark",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Christina W.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Chad Larsen",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Dustin L.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-23",
        "review_rate": 5.0,
        "customer_name": "Theresa Martinez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Adam W.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Tran",
        "service_type": "Garage Door Off Track",
        "tech_name": "Chelsey C.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-08",
        "review_rate": 5.0,
        "customer_name": "Erika Cameron",
        "service_type": "Garage Door Insulation",
        "tech_name": "Valerie S.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.9,
        "customer_name": "Virginia Khan",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Sherry B.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.9,
        "customer_name": "Tiffany Pennington",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Sarah D.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-07",
        "review_rate": 5.0,
        "customer_name": "Ashley Hogan",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Michael J.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.9,
        "customer_name": "Robert Henry",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Joe W.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.9,
        "customer_name": "Scott Mann",
        "service_type": "Garage Door Installation",
        "tech_name": "Megan O.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.8,
        "customer_name": "Lisa Dunn",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Shannon J.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.9,
        "customer_name": "Melissa Watts",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Sara S.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.8,
        "customer_name": "Lindsey Gray",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jessica W.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "Stephen Wade",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Joshua F.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.9,
        "customer_name": "Christopher Morris",
        "service_type": "Garage Door Insulation",
        "tech_name": "James F.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-11",
        "review_rate": 5.0,
        "customer_name": "Carrie Dixon",
        "service_type": "Garage Door Opener",
        "tech_name": "Kelly C.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.8,
        "customer_name": "Jamie Griffith",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kevin S.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "Sara Green",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Vickie W.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.8,
        "customer_name": "Sarah Burgess",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Casey S.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.9,
        "customer_name": "Donna Harvey",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Julie T.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-17",
        "review_rate": 5.0,
        "customer_name": "Jeffrey Tapia",
        "service_type": "Garage Door Installation",
        "tech_name": "Jamie S.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.9,
        "customer_name": "Michael Smith",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Joshua F.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.9,
        "customer_name": "David Taylor",
        "service_type": "Garage Door Insulation",
        "tech_name": "Rodney L.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.9,
        "customer_name": "Robert Lee",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Evan G.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.9,
        "customer_name": "Lisa Griffin",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Robert D.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-24",
        "review_rate": 5.0,
        "customer_name": "Dr. Mary Hawkins",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Alyssa W.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.9,
        "customer_name": "Wendy Allen",
        "service_type": "Garage Door Opener",
        "tech_name": "Elaine F.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.9,
        "customer_name": "Nicole Bailey",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jennifer T.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-24",
        "review_rate": 5.0,
        "customer_name": "Louis Tran",
        "service_type": "Garage Door Insulation",
        "tech_name": "Michele M.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.9,
        "customer_name": "Billy Snow",
        "service_type": "Garage Door Off Track",
        "tech_name": "Charles C.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.8,
        "customer_name": "William Peterson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Sherri P.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.8,
        "customer_name": "Richard Grant",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Lisa G.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Natasha Berg",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Maxwell R.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.9,
        "customer_name": "Steven Adams",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jay C.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.9,
        "customer_name": "Matthew Butler",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Judith K.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.8,
        "customer_name": "Daniel Torres",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Amy J.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.9,
        "customer_name": "Meagan Gill",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "David K.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.9,
        "customer_name": "Brittany Phillips",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Tracy T.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.8,
        "customer_name": "Crystal Fleming",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Marvin F.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.9,
        "customer_name": "Taylor Gay",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Andrew P.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.9,
        "customer_name": "Dwayne Williams",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Susan E.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-14",
        "review_rate": 5.0,
        "customer_name": "Ashley Harris",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Angela G.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-07",
        "review_rate": 5.0,
        "customer_name": "Alan Thompson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Steven H.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.9,
        "customer_name": "Johnathan Wong",
        "service_type": "Garage Door Services",
        "tech_name": "Melinda W.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "Kelly Cabrera",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Kristin S.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-13",
        "review_rate": 5.0,
        "customer_name": "Laura Wade",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Katherine T.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-28",
        "review_rate": 5.0,
        "customer_name": "Toni Ferguson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Melissa D.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.8,
        "customer_name": "Brittney Huffman",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Zachary R.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.9,
        "customer_name": "John Colon",
        "service_type": "Garage Door Off Track",
        "tech_name": "John T.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-31",
        "review_rate": 5.0,
        "customer_name": "Hannah Lopez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Carmen N.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "Phillip Kidd",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jessica J.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.8,
        "customer_name": "Joshua Pittman",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Brandon S.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-10",
        "review_rate": 5.0,
        "customer_name": "Jamie Martin",
        "service_type": "Garage Door Opener",
        "tech_name": "Larry S.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.9,
        "customer_name": "Laura Johnson MD",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Brandy D.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.9,
        "customer_name": "Jacob Mcdaniel",
        "service_type": "Garage Door Opener",
        "tech_name": "Laura M.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.8,
        "customer_name": "Daniel Cardenas",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Denise H.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-22",
        "review_rate": 5.0,
        "customer_name": "Cynthia Johnson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Andrew J.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "Linda Shelton",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Cassandra W.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.8,
        "customer_name": "Michael King",
        "service_type": "Garage Door Services",
        "tech_name": "Kelly M.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.9,
        "customer_name": "Oscar Miller",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Heather W.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.8,
        "customer_name": "Jacqueline Osborne",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jerry C.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-27",
        "review_rate": 5.0,
        "customer_name": "Aaron Fuller",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Steven B.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.9,
        "customer_name": "Jill Gaines",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Laura P.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.8,
        "customer_name": "Linda Morris",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Andre B.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-21",
        "review_rate": 5.0,
        "customer_name": "Gina Macias",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Robert M.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.9,
        "customer_name": "Michelle Jordan",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Michelle M.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-04",
        "review_rate": 5.0,
        "customer_name": "Wendy Mason",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Paul M.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.9,
        "customer_name": "Dr. Karen Espinoza",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Daniel P.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.9,
        "customer_name": "George Carney",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Ronald M.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.9,
        "customer_name": "John Parks",
        "service_type": "Garage Door Installation",
        "tech_name": "Tiffany H.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Foster",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Anthony W.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.9,
        "customer_name": "Mackenzie Brown",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Chad B.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.9,
        "customer_name": "Caroline Berry",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Christopher S.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-06",
        "review_rate": 5.0,
        "customer_name": "Connor Singh",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Brandon M.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "Andrew Lambert",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Sharon M.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.8,
        "customer_name": "Michelle Wilkinson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jonathan S.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "John Reynolds",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Bradley D.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.9,
        "customer_name": "Angelica Parks",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Linda R.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-24",
        "review_rate": 5.0,
        "customer_name": "Sarah Lozano",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Aimee B.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "Veronica Davis",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Christopher S.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.8,
        "customer_name": "Jane Huber",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Sarah D.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-06",
        "review_rate": 5.0,
        "customer_name": "John Swanson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kayla D.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "Stephen Knapp",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Sandy M.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.9,
        "customer_name": "Erica Dean",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Joshua F.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.9,
        "customer_name": "Amanda Hopkins",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Benjamin S.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.8,
        "customer_name": "Lisa Kelly",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Ashley J.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Logan Griffin",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Lindsey P.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "James Stone",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Monica D.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-26",
        "review_rate": 5.0,
        "customer_name": "Kathy Davis",
        "service_type": "Garage Door Installation",
        "tech_name": "Denise W.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.8,
        "customer_name": "Deanna Foster",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Christopher E.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.8,
        "customer_name": "Danny Cruz",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Charles D.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "Deanna Harris",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Matthew K.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.9,
        "customer_name": "Stephanie Nelson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Michael T.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.9,
        "customer_name": "Anita Lucas",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Randall T.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.9,
        "customer_name": "Tyler Long",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kevin B.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.9,
        "customer_name": "Andrew Leonard",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jessica A.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.9,
        "customer_name": "Bailey Williams",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kathleen H.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.9,
        "customer_name": "Joyce Warren",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jeffrey A.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.8,
        "customer_name": "Carrie Moore",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Natasha M.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.9,
        "customer_name": "Bradley Ellis",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Adam H.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.9,
        "customer_name": "Matthew Glover",
        "service_type": "Garage Door Repair",
        "tech_name": "Dawn B.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.8,
        "customer_name": "Kim Robinson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Daniel W.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Keith Griffin",
        "service_type": "Garage Door Off Track",
        "tech_name": "Molly M.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Frank Jones",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Laura H.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-06",
        "review_rate": 5.0,
        "customer_name": "Victoria Bonilla",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Manuel G.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.8,
        "customer_name": "Ethan Powers",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Daniel D.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.9,
        "customer_name": "Rachel Johnston",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Steven A.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.8,
        "customer_name": "Leslie Ramsey",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jason V.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.9,
        "customer_name": "Amber Hall",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Ryan M.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.8,
        "customer_name": "Brittney Harris",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Erik N.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-28",
        "review_rate": 5.0,
        "customer_name": "Dawn Stanley",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Beverly R.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Karen Chen",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Duane M.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-03",
        "review_rate": 5.0,
        "customer_name": "Zachary Mcdonald",
        "service_type": "Garage Door Off Track",
        "tech_name": "Robert C.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.8,
        "customer_name": "Brianna Rivas",
        "service_type": "Garage Door Opener",
        "tech_name": "Jeremy C.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "Charles Stone",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Phillip G.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "William Walter",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Katherine L.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.9,
        "customer_name": "Joshua Campbell",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Nicole C.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-16",
        "review_rate": 5.0,
        "customer_name": "James Howell",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Pamela G.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.9,
        "customer_name": "Katrina Anderson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Richard G.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-22",
        "review_rate": 5.0,
        "customer_name": "William Conway",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Ana T.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-29",
        "review_rate": 5.0,
        "customer_name": "Brandon Lopez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Brent M.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-02",
        "review_rate": 5.0,
        "customer_name": "Ricky Ruiz",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kelli R.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.9,
        "customer_name": "Rebecca Wright",
        "service_type": "Garage Door Services",
        "tech_name": "Keith A.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.8,
        "customer_name": "Joshua Wagner",
        "service_type": "Garage Door Installation",
        "tech_name": "Karen W.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.9,
        "customer_name": "Mr. Andrew Williams",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Megan S.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.9,
        "customer_name": "Carlos Fox",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Eric H.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Jamie Wallace",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kathleen K.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.8,
        "customer_name": "Rachel Harrison",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Nicholas H.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.9,
        "customer_name": "Ronald Hart",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Olivia M.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.9,
        "customer_name": "Hailey Bennett",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jack M.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-21",
        "review_rate": 5.0,
        "customer_name": "Nicholas Black",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "David S.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.9,
        "customer_name": "Keith Russell",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Mark W.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.9,
        "customer_name": "Timothy Richards",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Lance L.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-18",
        "review_rate": 5.0,
        "customer_name": "Gina Ali",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Sarah O.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-12",
        "review_rate": 5.0,
        "customer_name": "Katherine Howard",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Ernest W.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.9,
        "customer_name": "Kyle Walsh",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jamie G.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.8,
        "customer_name": "Austin Nichols",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Katherine A.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.8,
        "customer_name": "Jennifer Mcknight",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Susan G.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.9,
        "customer_name": "Linda Day",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kimberly H.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-24",
        "review_rate": 5.0,
        "customer_name": "Dana Williams",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kyle O.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-11",
        "review_rate": 5.0,
        "customer_name": "Connie Vega",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Becky H.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.9,
        "customer_name": "Mr. Michael Brown",
        "service_type": "Garage Door Opener",
        "tech_name": "Jasmine P.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.8,
        "customer_name": "Mr. Martin Campbell",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Laura P.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.9,
        "customer_name": "Tammy Clements",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jeremy B.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "Justin Holland",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Michael B.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.8,
        "customer_name": "Beth Romero",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Melanie W.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.8,
        "customer_name": "Mercedes Mitchell",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jerry K.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-02",
        "review_rate": 5.0,
        "customer_name": "John Ford",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Alicia P.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.8,
        "customer_name": "Diana Lewis",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Richard M.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.9,
        "customer_name": "Kenneth Scott",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "James N.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-15",
        "review_rate": 5.0,
        "customer_name": "Daniel Kelly",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Robert M.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.8,
        "customer_name": "Melanie King",
        "service_type": "Garage Door Services",
        "tech_name": "Carrie H.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Stephanie Carlson",
        "service_type": "Garage Door Repair",
        "tech_name": "Diana Y.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.8,
        "customer_name": "Jennifer Donovan",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Christine M.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.8,
        "customer_name": "Mr. Travis Walters",
        "service_type": "Garage Door Repair",
        "tech_name": "Hannah S.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-27",
        "review_rate": 5.0,
        "customer_name": "Cynthia Small",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Timothy J.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.9,
        "customer_name": "Tanya Richardson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kyle H.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.9,
        "customer_name": "Donna Reed",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Mary B.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-11",
        "review_rate": 5.0,
        "customer_name": "Erik Taylor",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Anthony A.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-06",
        "review_rate": 5.0,
        "customer_name": "Megan Webb",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Crystal G.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Rangel",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Elizabeth G.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.8,
        "customer_name": "Margaret Carrillo",
        "service_type": "Garage Door Off Track",
        "tech_name": "Rebecca K.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-12",
        "review_rate": 5.0,
        "customer_name": "Stanley Burgess",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Steven S.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.8,
        "customer_name": "Heather Bridges",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Ashlee A.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.9,
        "customer_name": "Juan Torres",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Dana L.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-16",
        "review_rate": 5.0,
        "customer_name": "Douglas Peters",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Brianna W.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.9,
        "customer_name": "Sabrina Barnett",
        "service_type": "Garage Door Installation",
        "tech_name": "Brandon E.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.9,
        "customer_name": "David Copeland",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Katrina R.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-06",
        "review_rate": 5.0,
        "customer_name": "Christopher Cabrera",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Christopher S.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Mark Craig",
        "service_type": "Garage Door Opener",
        "tech_name": "Pamela C.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.8,
        "customer_name": "Caroline Alexander",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Christopher W.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.8,
        "customer_name": "Dustin Morgan",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "David M.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.8,
        "customer_name": "Kelly Walter",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Mason H.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-24",
        "review_rate": 5.0,
        "customer_name": "John Garcia",
        "service_type": "Garage Door Opener",
        "tech_name": "Carol M.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.8,
        "customer_name": "Brittany Mckinney",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Maria I.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-26",
        "review_rate": 5.0,
        "customer_name": "Carolyn Santos",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Monica S.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Matthew Walsh",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Amy P.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-06",
        "review_rate": 5.0,
        "customer_name": "Thomas Duncan",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Angel C.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Sara Bautista",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Michael C.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Jacob Palmer",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Julian F.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Sandra Ramos",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jaime F.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.9,
        "customer_name": "Stephanie Knight",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Andrew M.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-06",
        "review_rate": 5.0,
        "customer_name": "Rachel Sharp",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Martin A.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-11",
        "review_rate": 5.0,
        "customer_name": "Shannon Ayala",
        "service_type": "Garage Door Repair",
        "tech_name": "Nicole N.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Christopher Gray",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Erika C.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.9,
        "customer_name": "Daniel Smith",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Cheryl E.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Kelly Wells",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Anthony P.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.8,
        "customer_name": "Nicholas Brooks",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Gary L.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.9,
        "customer_name": "Claudia Ryan",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Nancy M.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Christopher Page",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Aaron R.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.9,
        "customer_name": "Christopher House",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Paige L.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Melissa Rivera",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Tamara D.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.8,
        "customer_name": "Beverly Garrison",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Kristen B.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.8,
        "customer_name": "Susan Johnson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Paula W.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.8,
        "customer_name": "Joseph Manning",
        "service_type": "Garage Door Services",
        "tech_name": "Jake H.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.9,
        "customer_name": "Marie Ortiz",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Emma D.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Brittany Frazier",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Bradley N.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Jessica Smith",
        "service_type": "Garage Door Opener",
        "tech_name": "Sarah H.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-09-30",
        "review_rate": 5.0,
        "customer_name": "Alan Day",
        "service_type": "Garage Door Services",
        "tech_name": "April B.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.9,
        "customer_name": "Thomas Roberts",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Candace W.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.9,
        "customer_name": "Julie Garza",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "William S.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-29",
        "review_rate": 5.0,
        "customer_name": "William Dunn",
        "service_type": "Garage Door Repair",
        "tech_name": "Kendra R.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.8,
        "customer_name": "Donald Chavez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Andrew M.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.9,
        "customer_name": "Joseph Thompson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Shari K.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.9,
        "customer_name": "William Sullivan",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Christopher B.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.8,
        "customer_name": "Anthony Beard",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Ryan R.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.9,
        "customer_name": "Kimberly Davis",
        "service_type": "Garage Door Opener",
        "tech_name": "Joseph H.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "Michelle Diaz",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Claudia T.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.8,
        "customer_name": "Alexandra Singleton",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Robert D.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.9,
        "customer_name": "Matthew Ferguson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Lisa G.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.9,
        "customer_name": "Brandon Moss",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kathy H.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "Thomas Mason",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Debra R.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-04",
        "review_rate": 5.0,
        "customer_name": "Mary Summers",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Joseph V.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Stacey Stevens",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Stacey H.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Ashley Edwards",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Michelle R.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.9,
        "customer_name": "Matthew Ramirez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Kristi W.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.8,
        "customer_name": "Amber Sanders",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Lori Y.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.9,
        "customer_name": "Daniel Mendez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Ashley P.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.8,
        "customer_name": "Karen Dixon",
        "service_type": "Garage Door Insulation",
        "tech_name": "Amanda C.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-06",
        "review_rate": 5.0,
        "customer_name": "Megan Bradley",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Barbara C.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Sarah Marsh",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Gary O.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.8,
        "customer_name": "Kimberly Smith",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Bridget C.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.9,
        "customer_name": "Joy Murphy",
        "service_type": "Garage Door Opener",
        "tech_name": "Lisa L.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.8,
        "customer_name": "Suzanne Garcia",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Alan J.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.8,
        "customer_name": "Brittany Gillespie",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jason M.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-08",
        "review_rate": 5.0,
        "customer_name": "Andrew Harris",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Cindy W.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-29",
        "review_rate": 5.0,
        "customer_name": "Miranda Wilkinson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Patrick R.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.9,
        "customer_name": "Angela Young",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jason T.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Rachel Hansen",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Sandra C.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.8,
        "customer_name": "Gregory Montoya",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Barbara B.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-28",
        "review_rate": 5.0,
        "customer_name": "Jacob Burnett",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Christian H.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.9,
        "customer_name": "Janet Johnson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Monica P.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.9,
        "customer_name": "Matthew Weiss",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Danielle B.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-22",
        "review_rate": 5.0,
        "customer_name": "Samuel Johnson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Emily J.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.9,
        "customer_name": "Nathan Ramirez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Joseph M.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Brenda Turner",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Tony P.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.8,
        "customer_name": "Timothy Vaughn",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Randy M.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-19",
        "review_rate": 5.0,
        "customer_name": "Patricia Johnson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "William S.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.9,
        "customer_name": "Lisa Roth",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Victor T.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-20",
        "review_rate": 5.0,
        "customer_name": "Taylor Douglas",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jennifer C.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-26",
        "review_rate": 5.0,
        "customer_name": "Jeremy Wilkins",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Donald J.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.9,
        "customer_name": "Debbie Smith",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Lisa C.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.8,
        "customer_name": "Jordan Mcguire",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Adrian C.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Janet Perez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Kimberly B.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.9,
        "customer_name": "Joshua Anthony",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Raymond P.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.8,
        "customer_name": "Bill Meyer",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kelli E.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "Julie Morris",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Steven R.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.8,
        "customer_name": "Dr. Barbara Smith",
        "service_type": "Garage Door Off Track",
        "tech_name": "Emily H.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.8,
        "customer_name": "Rebekah Carr",
        "service_type": "Garage Door Opener",
        "tech_name": "Jeremy H.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-17",
        "review_rate": 5.0,
        "customer_name": "Thomas Davis",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Brenda J.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.9,
        "customer_name": "Makayla Johnson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jason J.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.9,
        "customer_name": "Mary Beltran",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Heidi L.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.9,
        "customer_name": "Alan Clarke",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Miguel J.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.9,
        "customer_name": "Lauren Barber",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Angela L.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.9,
        "customer_name": "Chris Peterson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Elizabeth G.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.9,
        "customer_name": "Logan Armstrong",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Margaret W.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.8,
        "customer_name": "Evan Jones",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Charles R.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.8,
        "customer_name": "Kathleen Villegas",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Alex W.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Brian Rocha",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kristy A.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "Emily Cole",
        "service_type": "Garage Door Installation",
        "tech_name": "Debbie M.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.9,
        "customer_name": "Brittany Pena",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Belinda G.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-04",
        "review_rate": 5.0,
        "customer_name": "Andrea Fisher",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jeremy B.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.8,
        "customer_name": "Jason Woods",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Brian R.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.8,
        "customer_name": "Charles Jones",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Ashley D.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.9,
        "customer_name": "Brian Morris",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Amy A.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.8,
        "customer_name": "David Nelson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Randall T.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.9,
        "customer_name": "Kenneth Rhodes",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Meghan A.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.8,
        "customer_name": "Andrew Williams",
        "service_type": "Garage Door Insulation",
        "tech_name": "Lauren R.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-26",
        "review_rate": 5.0,
        "customer_name": "Richard Price",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Rachel R.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-15",
        "review_rate": 5.0,
        "customer_name": "Tyrone Lee",
        "service_type": "Garage Door Off Track",
        "tech_name": "Fred M.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-30",
        "review_rate": 5.0,
        "customer_name": "Michael Trujillo",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Russell M.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-11",
        "review_rate": 5.0,
        "customer_name": "James Johnson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Susan G.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-01",
        "review_rate": 5.0,
        "customer_name": "Jordan Johnson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Karen M.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Harry Miller",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Sandra R.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.9,
        "customer_name": "Tyler Garcia",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Mathew G.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.9,
        "customer_name": "Mary Thomas",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Mark L.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Matthew Wallace",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Stephanie M.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.9,
        "customer_name": "Jacqueline Hansen",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Miguel W.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "Manuel Vasquez MD",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Miranda O.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.8,
        "customer_name": "James Turner",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Leah R.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-15",
        "review_rate": 5.0,
        "customer_name": "Kristin Watson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Rebecca H.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-04",
        "review_rate": 5.0,
        "customer_name": "Charlene Mcneil",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Cynthia B.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Carlos Dixon",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Mark Y.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.8,
        "customer_name": "Ms. Linda Mays",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Abigail D.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-01",
        "review_rate": 5.0,
        "customer_name": "John Jordan",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jade L.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Sharp",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Hannah B.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.8,
        "customer_name": "Jason Chandler",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Tammy W.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.9,
        "customer_name": "Ian White",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Courtney R.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-05",
        "review_rate": 5.0,
        "customer_name": "Stacey Hayes",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Edward H.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.8,
        "customer_name": "Mark Shaw",
        "service_type": "Garage Door Services",
        "tech_name": "Emily H.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.9,
        "customer_name": "Mary Taylor",
        "service_type": "Garage Door Installation",
        "tech_name": "Brian W.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-26",
        "review_rate": 5.0,
        "customer_name": "Dana Cuevas",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Michael F.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.9,
        "customer_name": "Rebecca Kennedy",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Denise W.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.8,
        "customer_name": "Christopher Barnes",
        "service_type": "Garage Door Repair",
        "tech_name": "Emily L.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Sarah Steele",
        "service_type": "Garage Door Services",
        "tech_name": "James D.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.8,
        "customer_name": "Martin Thomas",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Christina M.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.9,
        "customer_name": "Jennifer James",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Erika S.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.9,
        "customer_name": "Carl Montgomery",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Paul A.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "William Gentry",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Joseph C.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.8,
        "customer_name": "Christine Clark",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Lori G.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.9,
        "customer_name": "Anita Gordon",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Tammy L.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.9,
        "customer_name": "Sarah Carpenter",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Rebecca H.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-08",
        "review_rate": 5.0,
        "customer_name": "Michele Fletcher",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "James B.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.9,
        "customer_name": "Alexander Wise",
        "service_type": "Garage Door Insulation",
        "tech_name": "Daniel W.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.9,
        "customer_name": "Jason Harrison",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "John B.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Juan May",
        "service_type": "Garage Door Opener",
        "tech_name": "Robert F.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Joseph Rangel",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Kathleen J.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-08",
        "review_rate": 5.0,
        "customer_name": "Christina Wells",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Sharon B.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.8,
        "customer_name": "Carol Perkins",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Donna T.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.8,
        "customer_name": "Kevin Hernandez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Michael L.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "Kimberly Taylor",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Alexander L.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.9,
        "customer_name": "Matthew Vaughn",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Alejandra A.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-07",
        "review_rate": 5.0,
        "customer_name": "Hailey Nguyen",
        "service_type": "Garage Door Services",
        "tech_name": "Sandra G.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Melissa Poole DDS",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Bonnie J.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.9,
        "customer_name": "Mark Bruce",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Ricky D.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-20",
        "review_rate": 5.0,
        "customer_name": "Abigail Gutierrez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Brandy R.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.9,
        "customer_name": "Thomas Davis",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Alyssa H.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-11",
        "review_rate": 5.0,
        "customer_name": "Anthony Barry",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Matthew L.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.9,
        "customer_name": "Heather Foster",
        "service_type": "Garage Door Repair",
        "tech_name": "Charles T.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.9,
        "customer_name": "James Little",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Miguel C.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.9,
        "customer_name": "Amber Gordon MD",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Rhonda H.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.9,
        "customer_name": "Maria Simmons",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Heather E.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.9,
        "customer_name": "Rachel Walters",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Carla L.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.9,
        "customer_name": "John Gutierrez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Isabella R.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.9,
        "customer_name": "Jennifer Rodriguez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kenneth H.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-12",
        "review_rate": 5.0,
        "customer_name": "Paige Dunn",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Dawn D.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Dawn Hill",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Matthew S.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.8,
        "customer_name": "Maria Cobb",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "James J.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.8,
        "customer_name": "Christopher Meyers",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Karen R.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.9,
        "customer_name": "Garrett Lane",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Stephanie T.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.8,
        "customer_name": "Darlene Jordan",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Travis E.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.8,
        "customer_name": "Ashley Moore",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Kimberly G.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.9,
        "customer_name": "Joseph Le",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Mary F.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.8,
        "customer_name": "Morgan Perkins",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Andrew G.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-09",
        "review_rate": 5.0,
        "customer_name": "Brittney Ruiz",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Todd M.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Sarah Sanchez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Christopher H.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Christine Velasquez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Eric D.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-23",
        "review_rate": 5.0,
        "customer_name": "Richard Snow",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Tara C.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-08",
        "review_rate": 5.0,
        "customer_name": "Charles Lopez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Brian T.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-14",
        "review_rate": 5.0,
        "customer_name": "Megan Floyd",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "David L.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Emily Carter",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Alicia E.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.8,
        "customer_name": "Shelly White",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Peggy W.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-09",
        "review_rate": 5.0,
        "customer_name": "Shari Hoffman",
        "service_type": "Garage Door Opener",
        "tech_name": "Katherine B.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-24",
        "review_rate": 5.0,
        "customer_name": "Danielle Thompson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Shane J.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Wright",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Anthony T.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-16",
        "review_rate": 5.0,
        "customer_name": "Laurie Lewis",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Michael G.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.9,
        "customer_name": "Kathleen Wagner",
        "service_type": "Garage Door Opener",
        "tech_name": "Crystal L.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.8,
        "customer_name": "Anthony Kirk",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Rachel S.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "David English",
        "service_type": "Garage Door Installation",
        "tech_name": "Tracy H.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.8,
        "customer_name": "John Wright",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Melinda P.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.9,
        "customer_name": "Jose Jones",
        "service_type": "Garage Door Services",
        "tech_name": "Karen M.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-03",
        "review_rate": 5.0,
        "customer_name": "Lori Harris",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Pamela B.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.9,
        "customer_name": "Maria Russell",
        "service_type": "Garage Door Off Track",
        "tech_name": "Laura B.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.9,
        "customer_name": "Shannon Lopez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kathryn P.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.9,
        "customer_name": "Courtney Nguyen",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Xavier P.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "Mark Weiss",
        "service_type": "Garage Door Installation",
        "tech_name": "Sean W.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-02",
        "review_rate": 5.0,
        "customer_name": "Michelle Ramirez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Kathleen B.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-20",
        "review_rate": 5.0,
        "customer_name": "Katelyn Durham",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Angela M.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.8,
        "customer_name": "Corey Tucker",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Richard S.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.9,
        "customer_name": "Selena Gross",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Stephanie S.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-21",
        "review_rate": 5.0,
        "customer_name": "Margaret Morris",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Grant G.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.9,
        "customer_name": "Patricia Schwartz",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kaitlyn S.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Jessica White",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kenneth H.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-02",
        "review_rate": 5.0,
        "customer_name": "Nicholas Hawkins",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Melinda B.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.9,
        "customer_name": "Jason Simmons",
        "service_type": "Garage Door Repair",
        "tech_name": "Joseph P.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-04",
        "review_rate": 5.0,
        "customer_name": "Lori Wilson",
        "service_type": "Garage Door Installation",
        "tech_name": "Kimberly C.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Cody Smith",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jacob S.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.8,
        "customer_name": "William Freeman",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Manuel G.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.9,
        "customer_name": "Timothy Jimenez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Brenda S.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.9,
        "customer_name": "Thomas Obrien",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Lisa A.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.8,
        "customer_name": "Margaret Gardner",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jonathan M.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.9,
        "customer_name": "Dr. Dawn Mcbride",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jasmine M.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.9,
        "customer_name": "Mackenzie Francis",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Stephen P.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-26",
        "review_rate": 5.0,
        "customer_name": "Evelyn Stevens",
        "service_type": "Garage Door Insulation",
        "tech_name": "Madison N.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.8,
        "customer_name": "Michael Andrade",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Darrell C.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.8,
        "customer_name": "Deborah Murphy",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jon M.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.8,
        "customer_name": "Adam Owens",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Steven B.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.9,
        "customer_name": "Gary Mitchell",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Donna W.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.9,
        "customer_name": "Jennifer Lopez DVM",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Joshua W.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.8,
        "customer_name": "Amanda Chen",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kristina C.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.9,
        "customer_name": "Cheryl Davis",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Stacey P.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.8,
        "customer_name": "Brandon Dyer",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Robin S.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.9,
        "customer_name": "Stephen Holland",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Matthew W.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.8,
        "customer_name": "Natalie Johnson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Stephanie C.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.9,
        "customer_name": "Walter Moran",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "William H.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Cassandra Moore",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Hannah P.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.9,
        "customer_name": "Michael Carroll",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Patrick W.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.8,
        "customer_name": "Robert Caldwell",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Timothy S.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.9,
        "customer_name": "Robert Hooper",
        "service_type": "Garage Door Installation",
        "tech_name": "Michelle G.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.9,
        "customer_name": "Chase Carter",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Laura F.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.9,
        "customer_name": "Sarah Hart",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kylie M.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.8,
        "customer_name": "Lisa Velez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Stephanie B.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.8,
        "customer_name": "Michelle Johnson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jennifer G.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-17",
        "review_rate": 5.0,
        "customer_name": "Trevor James",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Brent R.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-05",
        "review_rate": 5.0,
        "customer_name": "Kenneth Vasquez",
        "service_type": "Garage Door Services",
        "tech_name": "Matthew G.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.8,
        "customer_name": "Dalton Curtis",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Julia B.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.9,
        "customer_name": "Maria Jordan",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Charles S.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-06",
        "review_rate": 5.0,
        "customer_name": "Phillip Banks",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Deborah Y.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Kimberly Brown",
        "service_type": "Garage Door Insulation",
        "tech_name": "Christina N.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-19",
        "review_rate": 5.0,
        "customer_name": "Justin Williams",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Ray C.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.9,
        "customer_name": "Christine Garrison",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Harold R.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.8,
        "customer_name": "Adrian Hays",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Beth A.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.8,
        "customer_name": "Cheyenne Miller",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Laura M.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-22",
        "review_rate": 4.9,
        "customer_name": "Rebecca Reeves",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Robin C.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.8,
        "customer_name": "Jamie Armstrong",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Megan P.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.8,
        "customer_name": "James Simmons",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Allison D.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.9,
        "customer_name": "Monica Hayes",
        "service_type": "Garage Door Opener",
        "tech_name": "Lauren M.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.9,
        "customer_name": "Kenneth Mcdaniel",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Andres P.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.8,
        "customer_name": "Mark Williams",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Anthony S.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "Erin Kemp",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Elizabeth L.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-16",
        "review_rate": 5.0,
        "customer_name": "Sara Jensen",
        "service_type": "Garage Door Off Track",
        "tech_name": "Isaac A.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-17",
        "review_rate": 5.0,
        "customer_name": "Nicole Fowler",
        "service_type": "Garage Door Off Track",
        "tech_name": "Julie K.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "Eric Brown",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Frank O.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-12",
        "review_rate": 5.0,
        "customer_name": "Sara Johnson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jennifer W.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.8,
        "customer_name": "Shawn Gallagher",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "John S.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.8,
        "customer_name": "Stephanie Castro",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Shelley C.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.8,
        "customer_name": "Matthew Stevenson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "William W.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.9,
        "customer_name": "Jade Turner",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Mary P.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.8,
        "customer_name": "Matthew Rosales",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Shelby T.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.8,
        "customer_name": "Andrea Barry",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Ryan W.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.9,
        "customer_name": "Stephanie Gilbert",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Adam J.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.9,
        "customer_name": "Cheryl Pratt",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Wanda K.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "Kenneth Miller",
        "service_type": "Garage Door Maintenance",
        "tech_name": "James K.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.8,
        "customer_name": "Donna Kennedy",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Wanda W.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.9,
        "customer_name": "Regina Martinez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Matthew A.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-30",
        "review_rate": 5.0,
        "customer_name": "James Casey",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Chase M.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.9,
        "customer_name": "Darryl Rodriguez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Kirk D.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.9,
        "customer_name": "Ryan Nguyen",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "James V.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-29",
        "review_rate": 5.0,
        "customer_name": "Annette Sanders",
        "service_type": "Garage Door Opener",
        "tech_name": "Raymond H.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-03",
        "review_rate": 5.0,
        "customer_name": "Derek Turner",
        "service_type": "Garage Door Opener",
        "tech_name": "Holly R.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-18",
        "review_rate": 5.0,
        "customer_name": "James Howell DDS",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "James H.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.8,
        "customer_name": "Shawn Miller",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Zachary C.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-23",
        "review_rate": 5.0,
        "customer_name": "Zachary Frank",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Thomas J.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.8,
        "customer_name": "Michael Martinez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Alexander K.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "Jennifer Berry",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Michael A.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.8,
        "customer_name": "Darrell Charles",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Kaitlin P.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.9,
        "customer_name": "David Henry",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Veronica M.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-05",
        "review_rate": 5.0,
        "customer_name": "Roger Wood",
        "service_type": "Garage Door Insulation",
        "tech_name": "Barbara P.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.8,
        "customer_name": "Susan Green",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jeanette D.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.9,
        "customer_name": "Robert Long",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Katie B.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-01",
        "review_rate": 5.0,
        "customer_name": "Steve Mack",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Anna L.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.9,
        "customer_name": "Jocelyn Cooper",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Stanley S.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.9,
        "customer_name": "Jennifer Potter",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Melanie T.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.8,
        "customer_name": "Stephanie Harrington",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Morgan O.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-17",
        "review_rate": 5.0,
        "customer_name": "Cory Zhang",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Joanna B.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.9,
        "customer_name": "Jamie Brown",
        "service_type": "Garage Door Opener",
        "tech_name": "James T.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.9,
        "customer_name": "Gary Turner",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "John S.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.8,
        "customer_name": "Michaela Garcia",
        "service_type": "Garage Door Opener",
        "tech_name": "Joshua M.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.8,
        "customer_name": "Erin Stanley",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Shannon L.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "Brenda Moore",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Vincent J.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Amber Waters",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Lauren C.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.8,
        "customer_name": "Mary Gonzalez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Angela W.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.9,
        "customer_name": "Jesse Tyler",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Anthony C.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.8,
        "customer_name": "Cheryl Potter",
        "service_type": "Garage Door Repair",
        "tech_name": "Veronica G.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-10",
        "review_rate": 5.0,
        "customer_name": "Joseph Hernandez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Leonard J.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-12",
        "review_rate": 5.0,
        "customer_name": "Peter Coleman",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Haley J.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.9,
        "customer_name": "Katelyn Martin",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Catherine B.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.8,
        "customer_name": "Gregory Martinez",
        "service_type": "Garage Door Installation",
        "tech_name": "Luis R.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-04",
        "review_rate": 5.0,
        "customer_name": "Veronica Byrd",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Denise B.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-12",
        "review_rate": 5.0,
        "customer_name": "Steven Wilson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Angela M.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-22",
        "review_rate": 5.0,
        "customer_name": "Tiffany Dominguez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Yolanda B.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.9,
        "customer_name": "Rachel Hill",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Melissa G.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Victoria Zimmerman",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kevin C.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.8,
        "customer_name": "Matthew Proctor",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Austin B.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.9,
        "customer_name": "Theresa Garcia",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Aaron C.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.9,
        "customer_name": "Shawn Lowe",
        "service_type": "Garage Door Repair",
        "tech_name": "Crystal B.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-21",
        "review_rate": 5.0,
        "customer_name": "Janet Kidd",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Collin P.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.9,
        "customer_name": "Tammy Owen DDS",
        "service_type": "Garage Door Maintenance",
        "tech_name": "James M.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.8,
        "customer_name": "Jennifer Jones",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Taylor M.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "Jacob Gilmore",
        "service_type": "Garage Door Insulation",
        "tech_name": "Lori C.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-26",
        "review_rate": 5.0,
        "customer_name": "Sara Olson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Linda F.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.9,
        "customer_name": "Alex Myers",
        "service_type": "Garage Door Repair",
        "tech_name": "Jerry H.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.8,
        "customer_name": "Danny Miller",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Patrick H.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.9,
        "customer_name": "Linda Rosales",
        "service_type": "Garage Door Opener",
        "tech_name": "Garrett L.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.9,
        "customer_name": "Frank Perez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Brianna H.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.9,
        "customer_name": "Matthew Johnson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Robert F.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-11",
        "review_rate": 5.0,
        "customer_name": "Alejandro Cook",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kayla W.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.9,
        "customer_name": "Amber Rhodes",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Diana Y.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Gardner DDS",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Derek F.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Davila",
        "service_type": "Garage Door Repair",
        "tech_name": "Carolyn H.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-04",
        "review_rate": 5.0,
        "customer_name": "Christopher Thomas",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jose Y.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "James Thompson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Melissa M.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.8,
        "customer_name": "Kaitlyn Little",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Katherine C.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.9,
        "customer_name": "Douglas Mcintosh",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Thomas S.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.8,
        "customer_name": "Daniel Vasquez",
        "service_type": "Garage Door Services",
        "tech_name": "Cheryl C.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.9,
        "customer_name": "Scott Hall",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Randall R.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.8,
        "customer_name": "James Duarte",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Debbie D.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.9,
        "customer_name": "Hailey Lopez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Deborah G.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.8,
        "customer_name": "Emily Campbell",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jessica C.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-19",
        "review_rate": 5.0,
        "customer_name": "Timothy Gilbert",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Tammy B.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.8,
        "customer_name": "Timothy Mccoy",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Scott C.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Norma Moyer",
        "service_type": "Garage Door Off Track",
        "tech_name": "Rachel C.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.8,
        "customer_name": "John Henderson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Nicole N.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.9,
        "customer_name": "John Carr",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Raymond M.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.8,
        "customer_name": "Denise Berg",
        "service_type": "Garage Door Installation",
        "tech_name": "Andrew B.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.9,
        "customer_name": "Jennifer Smith",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Catherine W.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Johnny Bentley",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Ryan B.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.8,
        "customer_name": "Anthony Rojas",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Melissa P.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.9,
        "customer_name": "Randy Johnson",
        "service_type": "Garage Door Services",
        "tech_name": "Elizabeth S.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-15",
        "review_rate": 5.0,
        "customer_name": "Rachel Trujillo",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kelly C.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.9,
        "customer_name": "Michael Diaz",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kyle G.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.8,
        "customer_name": "Lisa Palmer",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Christopher W.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.9,
        "customer_name": "Samantha Lee",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Thomas C.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-19",
        "review_rate": 5.0,
        "customer_name": "Harry Maldonado",
        "service_type": "Garage Door Off Track",
        "tech_name": "Sherry Y.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.9,
        "customer_name": "Billy Young",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Vicki J.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.8,
        "customer_name": "Tiffany Reed",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Marvin M.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-04",
        "review_rate": 5.0,
        "customer_name": "Jack Deleon",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Susan H.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.8,
        "customer_name": "Michael Velazquez",
        "service_type": "Garage Door Services",
        "tech_name": "Jonathan L.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.8,
        "customer_name": "Eric Stout",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Robert B.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.9,
        "customer_name": "Jonathan Padilla",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Diana L.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.8,
        "customer_name": "Denise Austin",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Daryl M.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.9,
        "customer_name": "Jason Johnson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Joshua C.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-21",
        "review_rate": 5.0,
        "customer_name": "Christopher Martin",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Rebekah T.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.9,
        "customer_name": "Shawn Garcia",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jonathan K.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "Kimberly Black",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Christina K.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-30",
        "review_rate": 5.0,
        "customer_name": "Jill Jones",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Corey L.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.8,
        "customer_name": "Daniel Huang",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jenna H.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.9,
        "customer_name": "Jason Torres",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Peter S.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "James Bennett",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Cassandra G.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.8,
        "customer_name": "Jacqueline Vaughn",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Teresa J.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Jacqueline Gilbert",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Christy E.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-03",
        "review_rate": 5.0,
        "customer_name": "Shelly Odom",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jeffrey B.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.9,
        "customer_name": "Maria Sampson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Sharon W.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.9,
        "customer_name": "Ruth Cole",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kristine C.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-22",
        "review_rate": 5.0,
        "customer_name": "Jerry Austin",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Lindsay H.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.8,
        "customer_name": "John Meyer",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "John C.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.8,
        "customer_name": "Ethan Armstrong",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Joshua C.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Sandra Price",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Lori C.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.9,
        "customer_name": "Valerie Sanchez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Denise T.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.9,
        "customer_name": "Douglas Carr",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jennifer P.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.8,
        "customer_name": "Steven Cook",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Brittany P.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.9,
        "customer_name": "Mark Edwards",
        "service_type": "Garage Door Services",
        "tech_name": "Michael H.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.9,
        "customer_name": "Sean Fowler",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Richard B.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.9,
        "customer_name": "Diana Stone",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Christina S.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.8,
        "customer_name": "Brittany Rowe",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Danielle B.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-18",
        "review_rate": 5.0,
        "customer_name": "Jeremy Thompson",
        "service_type": "Garage Door Installation",
        "tech_name": "Tiffany K.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.8,
        "customer_name": "Bryan Lopez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Derrick B.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-01",
        "review_rate": 5.0,
        "customer_name": "Tammy Pearson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Terri M.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-24",
        "review_rate": 5.0,
        "customer_name": "Casey Hull",
        "service_type": "Garage Door Off Track",
        "tech_name": "Steven R.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-26",
        "review_rate": 5.0,
        "customer_name": "Jason Williams",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Donald B.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "Carrie Scott",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "David L.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-31",
        "review_rate": 5.0,
        "customer_name": "Melissa Rich",
        "service_type": "Garage Door Installation",
        "tech_name": "Noah W.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.8,
        "customer_name": "Benjamin Smith",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "David B.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.9,
        "customer_name": "Frank Hernandez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Michael P.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.9,
        "customer_name": "Thomas Sutton",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Cory K.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.8,
        "customer_name": "Jennifer Clayton",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Brian A.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-14",
        "review_rate": 5.0,
        "customer_name": "Kristin Tanner",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Cynthia C.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.8,
        "customer_name": "James Hart",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Timothy S.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "Thomas Benjamin",
        "service_type": "Garage Door Repair",
        "tech_name": "Alison A.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-26",
        "review_rate": 5.0,
        "customer_name": "James English",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Tammy Q.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-22",
        "review_rate": 5.0,
        "customer_name": "Cynthia Gentry",
        "service_type": "Garage Door Opener",
        "tech_name": "Robert D.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Tyler Campbell",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Rachel G.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-17",
        "review_rate": 5.0,
        "customer_name": "Charles Douglas",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jamie P.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.9,
        "customer_name": "Steven Clark",
        "service_type": "Garage Door Repair",
        "tech_name": "Jennifer A.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.9,
        "customer_name": "Christine Oliver",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Carol F.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "Hunter Levine",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Sandra D.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.8,
        "customer_name": "Rodney Bradshaw",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jacqueline H.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.8,
        "customer_name": "Tiffany Barton",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Maria C.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.9,
        "customer_name": "Jessica Jones",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Ashley M.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.9,
        "customer_name": "Margaret Riley",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Sarah T.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.9,
        "customer_name": "Janet Harris",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Tonya H.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.8,
        "customer_name": "Katie Robinson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Dawn B.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-11",
        "review_rate": 5.0,
        "customer_name": "William Cooley",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Joe P.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.9,
        "customer_name": "Kyle King",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Brittany B.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.8,
        "customer_name": "Craig Stephens",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jose B.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.9,
        "customer_name": "Cassie Flores",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "John W.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.8,
        "customer_name": "Victoria Smith",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Ian T.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-16",
        "review_rate": 5.0,
        "customer_name": "Johnny Steele",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Katherine P.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-12",
        "review_rate": 5.0,
        "customer_name": "Charles Jones",
        "service_type": "Garage Door Repair",
        "tech_name": "Mark H.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.8,
        "customer_name": "Kristin Medina",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Brooke B.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.9,
        "customer_name": "Kathy Burke",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Nancy V.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.8,
        "customer_name": "Judy Brown",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Marcus C.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.8,
        "customer_name": "Tina Camacho",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jonathan J.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-01",
        "review_rate": 5.0,
        "customer_name": "Kyle Ferguson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Robert S.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.8,
        "customer_name": "Melanie Graves",
        "service_type": "Garage Door Services",
        "tech_name": "Jessica R.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.9,
        "customer_name": "Stephanie Carson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "William B.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.9,
        "customer_name": "Mikayla Pitts",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Daniel G.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Edward Clark",
        "service_type": "Garage Door Services",
        "tech_name": "Alexis B.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.9,
        "customer_name": "Aaron Willis",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Alan H.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "Miguel Gardner",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Gregory E.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-04",
        "review_rate": 5.0,
        "customer_name": "Bradley Young",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jason M.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.9,
        "customer_name": "Timothy Preston",
        "service_type": "Garage Door Opener",
        "tech_name": "Brian O.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.9,
        "customer_name": "Mary Owens",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jacob P.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Ryan Rivas",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Brandi C.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Patricia Jones",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Erin E.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.8,
        "customer_name": "Holly Fox",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Richard B.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Jaime Hoffman",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Denise N.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-14",
        "review_rate": 5.0,
        "customer_name": "Christine Cooper",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jonathan W.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "Morgan Duncan",
        "service_type": "Garage Door Services",
        "tech_name": "Jill A.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.8,
        "customer_name": "Joshua Lopez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Timothy S.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Neil Duncan",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jeremy S.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.9,
        "customer_name": "Trevor Strong",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Mary M.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.8,
        "customer_name": "Kaitlyn Davis",
        "service_type": "Garage Door Services",
        "tech_name": "Kristin H.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.9,
        "customer_name": "Jason Gamble",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Laurie B.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.9,
        "customer_name": "Kimberly Norris",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Richard S.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.8,
        "customer_name": "Tina Gutierrez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Fernando K.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-28",
        "review_rate": 5.0,
        "customer_name": "Sean Foster",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Samantha J.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.8,
        "customer_name": "Joseph Mayer",
        "service_type": "Garage Door Off Track",
        "tech_name": "Caitlin T.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.9,
        "customer_name": "Robert Moore",
        "service_type": "Garage Door Services",
        "tech_name": "Jason P.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-09",
        "review_rate": 5.0,
        "customer_name": "Joshua George",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Angela J.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.9,
        "customer_name": "Brandon Moore",
        "service_type": "Garage Door Insulation",
        "tech_name": "Emily R.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.9,
        "customer_name": "Paul Gillespie",
        "service_type": "Garage Door Repair",
        "tech_name": "Joan D.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "Rachel Johnson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Meagan W.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.8,
        "customer_name": "Brian Henry",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "David B.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.8,
        "customer_name": "Nicole Santiago",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Shelley S.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.8,
        "customer_name": "Richard Burns",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jessica L.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.9,
        "customer_name": "Brian Schmidt",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Tracy S.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Michael Beck",
        "service_type": "Garage Door Opener",
        "tech_name": "James A.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.9,
        "customer_name": "Stephanie Wallace",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jeffrey M.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-01",
        "review_rate": 5.0,
        "customer_name": "Dakota Sanchez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Sarah R.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-22",
        "review_rate": 5.0,
        "customer_name": "Dennis Wells",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Bethany D.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-17",
        "review_rate": 5.0,
        "customer_name": "Nicholas Pearson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Michael B.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.8,
        "customer_name": "Dale Moore",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "David C.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-23",
        "review_rate": 5.0,
        "customer_name": "Brian Lopez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Veronica B.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.8,
        "customer_name": "Dr. Kelli Faulkner",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jesse H.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "Danielle Ramirez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jill B.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.8,
        "customer_name": "Tracy Lane",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Mark H.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.8,
        "customer_name": "Haley Hurst",
        "service_type": "Garage Door Installation",
        "tech_name": "Stanley C.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.8,
        "customer_name": "Mindy Coleman",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Angela O.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.8,
        "customer_name": "Gwendolyn Reeves",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Andrew T.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.9,
        "customer_name": "Anna Perkins",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Nathan L.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.9,
        "customer_name": "Ann Newton",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Deborah J.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.9,
        "customer_name": "Paul Mata Jr.",
        "service_type": "Garage Door Installation",
        "tech_name": "Joseph T.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.8,
        "customer_name": "Michael Hunt",
        "service_type": "Garage Door Track Repair",
        "tech_name": "James M.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.9,
        "customer_name": "James Vasquez",
        "service_type": "Garage Door Repair",
        "tech_name": "Debra K.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-25",
        "review_rate": 5.0,
        "customer_name": "Kelly Lawson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jacob M.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Sarah Robinson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Steven R.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.8,
        "customer_name": "Alyssa Thomas",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jennifer B.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.9,
        "customer_name": "Alexis Thompson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Sarah W.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-12",
        "review_rate": 5.0,
        "customer_name": "Brian Terry",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Thomas S.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.9,
        "customer_name": "Mary Kennedy",
        "service_type": "Garage Door Opener",
        "tech_name": "Victoria J.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-09",
        "review_rate": 5.0,
        "customer_name": "Thomas Evans",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Karen R.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Justin Brennan",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Abigail D.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-22",
        "review_rate": 5.0,
        "customer_name": "Catherine Morris",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Holly W.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.8,
        "customer_name": "Scott Gardner",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Debbie M.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Janet Fields DVM",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Paige P.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.9,
        "customer_name": "Lee Carter",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Eric J.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-02",
        "review_rate": 5.0,
        "customer_name": "Michael Ortega",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Lori M.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.9,
        "customer_name": "Deborah Walters",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Michael A.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-22",
        "review_rate": 5.0,
        "customer_name": "Barbara Martinez",
        "service_type": "Garage Door Repair",
        "tech_name": "Tonya W.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.9,
        "customer_name": "Lacey Bartlett",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Terry K.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-25",
        "review_rate": 5.0,
        "customer_name": "Sara Miller",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "John D.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.8,
        "customer_name": "Jennifer Osborne",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Melissa S.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-16",
        "review_rate": 5.0,
        "customer_name": "Ashley Owens",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jeffrey H.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-31",
        "review_rate": 5.0,
        "customer_name": "Mark Vasquez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Kelsey B.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-04",
        "review_rate": 5.0,
        "customer_name": "Bryan Moore",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jason G.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-17",
        "review_rate": 5.0,
        "customer_name": "Stacey Gonzalez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Gregory C.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.9,
        "customer_name": "Rebecca Gonzalez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Rick S.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-05",
        "review_rate": 5.0,
        "customer_name": "Lauren Erickson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Ashley H.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.9,
        "customer_name": "Erica Barnett",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Paul M.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.9,
        "customer_name": "Leslie Riley",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "John J.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.8,
        "customer_name": "Sean Gilbert",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Steven C.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.8,
        "customer_name": "Thomas Mora",
        "service_type": "Garage Door Track Repair",
        "tech_name": "James M.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.9,
        "customer_name": "Amber Garcia",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Cody N.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.9,
        "customer_name": "Alexis Wolf",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Whitney H.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-12",
        "review_rate": 5.0,
        "customer_name": "Lisa Anderson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Andrea H.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.8,
        "customer_name": "Carlos Alexander",
        "service_type": "Garage Door Services",
        "tech_name": "Donald G.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.8,
        "customer_name": "Madeline Benson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Brett J.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-29",
        "review_rate": 5.0,
        "customer_name": "Victoria James",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Bryan F.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.8,
        "customer_name": "Andrew Houston",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kevin M.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.8,
        "customer_name": "Heather Park",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Susan G.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.9,
        "customer_name": "John Lewis",
        "service_type": "Garage Door Installation",
        "tech_name": "Pamela G.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.8,
        "customer_name": "Mark Holden",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Corey C.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.8,
        "customer_name": "Adriana Lee",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Pamela F.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.9,
        "customer_name": "Sabrina Herring",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Karen M.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.9,
        "customer_name": "Franklin Lewis",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Raymond J.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.9,
        "customer_name": "Mark Houston",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kenneth M.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.8,
        "customer_name": "Brandon Hernandez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Sean B.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "Kristy Wood",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kayla M.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.9,
        "customer_name": "Michael Thomas",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Dawn L.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "Deanna Ray",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Sheila D.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.9,
        "customer_name": "Philip Bryant",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Lindsey K.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.9,
        "customer_name": "Tracy Silva",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Shannon J.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-06",
        "review_rate": 5.0,
        "customer_name": "Deanna Sanchez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jeffrey M.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-03",
        "review_rate": 5.0,
        "customer_name": "Rebecca Yu",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Carlos D.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-20",
        "review_rate": 5.0,
        "customer_name": "Andrew Edwards",
        "service_type": "Garage Door Services",
        "tech_name": "Matthew R.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.8,
        "customer_name": "Jeffery Castillo",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Michael P.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.9,
        "customer_name": "Brandi Finley",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Samuel S.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.8,
        "customer_name": "Tara Melton",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Sandra R.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    }
]
export default LocationReviewsData;

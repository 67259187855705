import React, { useState } from 'react'
import leftMenu from '../../Images/leftMenu.png'
import closeMenu from '../../Images/closeMenu.png'
import Banner from '../../Images/Banner.png'
import telephone from '../../Images/telephone.png'
import book from '../../Images/book.png'
import './Header.scss';
import AreaData from '../../Components/ServicesPage/AreaData'
import Arrow from '../../Images/downArrow.png'
import LocationData from '../../Components/LocationAll/LocationData'

export default function Header({ showMenu, setShowMenu, navigateTo, WebSitePages }) {
    const [header, setHeader] = useState(false)

    // const { pathname } = useLocation();
    // if (pathname === "/order") return null;
    return (
        <div className="Header">

            <div className="HeaderTopComputer">
                <div className="Header2" >
                    <img src={Banner} alt="Garage Door In Thousand Oaks, CA | Garage Door Repair In Thousand Oaks, CA | Garage Door Services In Thousand Oaks, CA | Garage Door Inspection In Thousand Oaks, CA" />
                    <a href="/">Thousand Oaks GARAGE DOOR</a>
                </div>

                <div className="Header1">
                    <div className="Header1Box">
                        <div className="Header1BoxHedaer">
                            <a href="/">Home</a>
                        </div>
                    </div>
                    <div className="Header1Box">
                        <div className="Header1BoxHedaer">
                            <h1>Services</h1>
                            <img src={Arrow} alt="Garage Door In Thousand Oaks, CA | Garage Door Repair In Thousand Oaks, CA | Garage Door Services In Thousand Oaks, CA | Garage Door Inspection In Thousand Oaks, CA" />
                        </div>

                        <div className="Header1BoxMenu">
                            {AreaData.map((A, index) =>
                                <a key={index} href={A.Path} className="Header1BoxMenuBox">
                                    {A.TitleShort}
                                </a>
                            )}
                        </div>
                    </div>
                    <div className="Header1Box">
                        <div className="Header1BoxHedaer">
                            <a href="/locations">Locations</a>
                        </div>
                    </div>
                    <div className="Header1Box">
                        <div className="Header1BoxHedaer">
                        <a href={`/reviews/${LocationData.find(a => a.City == ("Thousand Oaks").replace("%20", '')).City.replace(" ", '').toLowerCase()}`}>Reviews</a>
                        </div>
                    </div>
                    <div className="Header1Box">
                        <div className="Header1BoxHedaer">
                            <a href="/contact">Contact Us</a>
                        </div>
                    </div>
                    <div className="Header1Box">
                        <div className="Header1BoxHedaer">
                            <a href="/blog">Blog</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="HeaderTop">
                <div className="Header2" >
                    <img src={Banner} alt="Garage Door In Thousand Oaks, CA | Garage Door Repair In Thousand Oaks, CA | Garage Door Services In Thousand Oaks, CA | Garage Door Inspection In Thousand Oaks, CA" />
                    <a href="/">Thousand Oaks GARAGE DOOR</a>
                </div>
                <div className='Header3'>
                    {showMenu ?
                        <img
                            style={header ? { "filter": "invert(0)", "width": "30px" } : { "filter": "invert(1)", "width": "30px" }}
                            src={closeMenu} alt="Garage Door In Thousand Oaks, CA | Garage Door Repair In Thousand Oaks, CA | Garage Door Services In Thousand Oaks, CA | Garage Door Inspection In Thousand Oaks, CA"
                            className='menuCloseBtn'
                            onClick={() => setShowMenu(false)}
                        />
                        :
                        <img
                            style={header ? { "filter": "invert(0)" } : { "filter": "invert(1)" }}
                            src={leftMenu} alt="Garage Door In Thousand Oaks, CA | Garage Door Repair In Thousand Oaks, CA | Garage Door Services In Thousand Oaks, CA | Garage Door Inspection In Thousand Oaks, CA"
                            onClick={() => setShowMenu(true)}
                        />
                    }

                </div>
            </div>


            <div className="HeaderBottom">
                <div className='Header2'>
                    <div className="Header2Box" onClick={() => navigateTo("contact")}>
                        <h2>CONTACT US</h2>
                    </div>
                    <div className="Header2Box" onClick={() => navigateTo("faq")}>
                        <h2 >FAQS</h2>
                    </div>
                </div>
                <div className="Header1Main">
                    <div className='Header1'>
                        <a href="/book">SCHEDULE ONLINE<img src={book} alt="Garage Door In Thousand Oaks, CA | Garage Door Repair In Thousand Oaks, CA | Garage Door Services In Thousand Oaks, CA | Garage Door Inspection In Thousand Oaks, CA" /></a>
                    </div>
                    <div className='Header1'>
                        <a href="tel:805-977-5620">CALL 805-977-5620<img src={telephone} alt="Garage Door In Thousand Oaks, CA | Garage Door Repair In Thousand Oaks, CA | Garage Door Services In Thousand Oaks, CA | Garage Door Inspection In Thousand Oaks, CA" /></a>
                    </div>
                </div>
            </div>

        </div>
    )
}
